
import { defineComponent, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import {
  orderAssginTable,
  OrderAssginTable,
} from "@/core/directive/interface/orderAssign";
import {modalShowListener, showModal} from "@/core/directive/function/common";

export default defineComponent({
  name: "mb-order-routing-routing-overview-return",
  props: {
    tableItemData: {
      type: Object as () => OrderAssginTable,
      default: () => {
        return Object.assign({}, orderAssginTable);
      },
    },
  },
  emits: ["continue-return-refund"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const orderRoutingReturnRefundModalRef = ref<CommonHtmlType>(null);
    const isChecked = ref<boolean>(false);
    const isContinue = ref<boolean>(false);

    const onContinueButtonClick = () => {
      isContinue.value = true;
      hideModal(orderRoutingReturnRefundModalRef.value);
    };

    const handleModalHidden = () => {
      setTimeout(() => {
        if (isContinue.value) {
          emit("continue-return-refund", { ...props.tableItemData });
        }
      });
    };

    const modalShow = () => {
      showModal(orderRoutingReturnRefundModalRef.value);
    };

    const modalHide = () => {
      hideModal(orderRoutingReturnRefundModalRef.value);
      isContinue.value = false;
      isChecked.value = false;
    };

    onMounted(() => {
      modalShowListener(orderRoutingReturnRefundModalRef.value, () => {
        isContinue.value = false;
        isChecked.value = false;
      });
      modalHideListener(
        orderRoutingReturnRefundModalRef.value,
        handleModalHidden
      );
    });

    return {
      t,
      props,
      orderRoutingReturnRefundModalRef,
      loading,
      isChecked,
      onContinueButtonClick,
      modalShow,
      modalHide,
    };
  },
});


import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  MerchantOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import { ApiOrderAssign } from "@/core/api";
import {
  orderAssginMerchantOrderFilterOption,
  OrderAssginMerchantOrderFulfilledList,
  OrderAssginMerchantOrderRuleMap,
  OrderAssginMerchantOrderSubTable,
  OrderAssginMerchantOrderTable,
  OrderAssginMerchantOrderTableRule,
} from "@/core/directive/interface/orderAssign";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  formatDate,
  formatDateTime,
  removeReadonly,
} from "@/core/directive/function/common";
import { InventoryRuleKey } from "@/core/directive/type/inventoryRule";
import accounting from "accounting-js";
import RelationFulfillment from "@/views/merchant-order/order-request/RelationFulfillment.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import { commonGalleryImages } from "@/core/directive/function/order";

interface ruleItem {
  amount: number;
  pcs: number;
  skuPcs: Map<string, number>;
}

export default defineComponent({
  name: "mb-order-routing-routing-overview-merchant-order-list",
  props: {
    store_id: {
      type: String,
      default: "",
    },
  },
  components: {
    // CommonProduct,
    RelationFulfillment,
    SkuInfo,
    ProductInfo,
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const merchantOrderList = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonHtmlType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      ids: [] as number[][],
      channel_list: [] as string[],
      fulfilled_list: [] as OrderAssginMerchantOrderFulfilledList[],
      rule_list: new Map<string, OrderAssginMerchantOrderRuleMap>([]),
      items: [] as OrderAssginMerchantOrderTable[],
      filterOptions: Object.assign({}, orderAssginMerchantOrderFilterOption),
      channel_number: 0,
      sku_number: 0,
      itemIndex: 0,
      subIndex: 0,
      subQuantity: 1,
      subMaxQuantity: 1,
      merchantLoading: false,
      merchant: [] as MerchantOption[],
      channel: [] as TaggingItem[],
      channelOption: {},
    });

    const rules = ref({});

    const options = ref({});

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const getSalesChannelsData = async (id?, remake?) => {
      if (remake) {
        formData.value.filterOptions.channel = [];
        formData.value.channel = [];
        Object.keys(formData.value.channelOption).forEach((index) => {
          if (formData.value.filterOptions.merchant == index) {
            formData.value.channel = formData.value.channelOption[index];
          }
        });
      }
      debounceSearch();
    };

    const toggleItemSelect = (item: OrderAssginMerchantOrderTable) => {
      item.isChecked = !item.isChecked;
      let isChecked = item.isChecked;
      item.items.forEach((row) => {
        row.isChecked = isChecked === true ? false : true;
        toggleSubSelect(item, row);
      });
    };

    const toggleSubSelect = (
      item: OrderAssginMerchantOrderTable,
      sub: OrderAssginMerchantOrderSubTable
    ) => {
      sub.isChecked = !sub.isChecked;
      if (sub.isChecked) {
        if (!item.fulfill_ids.has(sub.fulfill_ids)) {
          item.fulfill_ids.set(sub.fulfill_ids, sub);
          formData.value.ids.push(sub.fulfill_ids);
          formData.value.fulfilled_list.push({
            channel: item.channel_id,
            fulfill_ids: sub.fulfill_ids,
            qty: sub.qty,
            sku_id: sub.sku_id,
          });
        }
      } else {
        if (item.fulfill_ids.has(sub.fulfill_ids)) {
          item.fulfill_ids.delete(sub.fulfill_ids);
          formData.value.ids.splice(
            formData.value.ids.indexOf(sub.fulfill_ids),
            1
          );
          formData.value.fulfilled_list.splice(
            formData.value.ids.indexOf(sub.fulfill_ids),
            1
          );
        }
      }
      const fulfill_ids = Array.from(item.fulfill_ids.keys());
      item.isChecked = fulfill_ids.length === item.items.length;
      checkRules();
    };

    const handleQtyChange = (value: number) => {
      console.log(value);
      checkRules();
    };

    //验证PIM库存规则
    const checkRules = () => {
      console.log("============Check Rules Start============");
      let flag = true;
      formData.value.items.forEach((merchantItem) => {
        const skuItems = merchantItem.items;
        const rules = merchantItem.rules;
        let hasSubChecked = false;
        let selectedQty = 0;
        let selectedSubtotal = 0;
        const brandMap: Map<string, ruleItem> = new Map();
        const categoryMap: Map<string, ruleItem> = new Map();
        const skuPcsMap: Map<string, number> = new Map();
        skuItems.forEach((item) => {
          if (item.isChecked) {
            hasSubChecked = true;
            selectedQty += item.qty;
            selectedSubtotal += item.qty * Number(item.original_price);
            if (brandMap.has(item.brand)) {
              let brandItem = brandMap.get(item.brand);
              let brandAmount =
                (brandItem?.amount ? brandItem?.amount : 0) +
                item.qty * Number(item.original_price);
              let brandPcs = (brandItem?.pcs ? brandItem?.pcs : 0) + item.qty;
              let brandSkuPcsMap: Map<string, number> = new Map();
              brandSkuPcsMap = brandItem?.skuPcs as Map<string, number>;
              if (brandSkuPcsMap.has(item.sku_id)) {
                let brandSkuPcs =
                  ((brandItem?.skuPcs?.get(item.sku_id) as number)
                    ? (brandItem?.skuPcs?.get(item.sku_id) as number)
                    : 0) + item.qty;
                brandSkuPcsMap.set(item.sku_id, brandSkuPcs);
              } else {
                brandSkuPcsMap.set(item.sku_id, item.qty);
              }
              brandMap.set(item.brand, {
                amount: brandAmount,
                pcs: brandPcs,
                skuPcs: brandSkuPcsMap,
              });
            } else {
              let brandAmount = item.qty * Number(item.original_price);
              let brandSkuPcsMap: Map<string, number> = new Map();
              brandSkuPcsMap.set(item.sku_id, item.qty);
              brandMap.set(item.brand, {
                amount: brandAmount,
                pcs: item.qty,
                skuPcs: brandSkuPcsMap,
              });
            }
            if (skuPcsMap.has(item.sku_id)) {
              let qtySubtotal = Number(skuPcsMap.get(item.sku_id)) + item.qty;
              skuPcsMap.set(item.sku_id, qtySubtotal);
            } else {
              let qtySubtotal = item.qty;
              skuPcsMap.set(item.sku_id, qtySubtotal);
            }
            item.category.forEach((cat) => {
              if (categoryMap.has(cat)) {
                let categoryItem = categoryMap.get(cat);
                let categoryAmount =
                  (categoryItem?.amount ? categoryItem?.amount : 0) +
                  item.qty * Number(item.original_price);
                let categoryPcs =
                  (categoryItem?.pcs ? categoryItem?.pcs : 0) + item.qty;
                let categorySkuPcsMap: Map<string, number> = new Map();
                categorySkuPcsMap = categoryItem?.skuPcs as Map<string, number>;
                if (categorySkuPcsMap.has(item.sku_id)) {
                  let categorySkuPcs =
                    ((categoryItem?.skuPcs.get(item.sku_id) as number)
                      ? (categoryItem?.skuPcs.get(item.sku_id) as number)
                      : 0) + item.qty;
                  categorySkuPcsMap.set(item.sku_id, categorySkuPcs);
                } else {
                  categorySkuPcsMap.set(item.sku_id, item.qty);
                }
                categoryMap.set(cat, {
                  amount: categoryAmount,
                  pcs: categoryPcs,
                  skuPcs: categorySkuPcsMap,
                });
              } else {
                let categoryAmount = item.qty * Number(item.original_price);
                let categorySkuPcsMap: Map<string, number> = new Map();
                categorySkuPcsMap.set(item.sku_id, item.qty);
                categoryMap.set(cat, {
                  amount: categoryAmount,
                  pcs: item.qty,
                  skuPcs: categorySkuPcsMap,
                });
              }
            });

            if (rules.length > 0) {
              const amountErrorRuleMap = new Map<
                number,
                OrderAssginMerchantOrderTableRule
              >([]);
              const pcsErrorRuleMap = new Map<
                number,
                OrderAssginMerchantOrderTableRule
              >([]);
              const skuPcsErrorRuleMap = new Map<
                number,
                OrderAssginMerchantOrderTableRule
              >([]);
              rules.forEach((rule, index) => {
                if (
                  item.brand == rule.brand &&
                  item.category.includes(rule.category)
                ) {
                  if (rule.key_type == 10) {
                    //周期内金额限制
                    if (
                      item.qty * Number(item.original_price) >
                      Number(rule.value)
                    ) {
                      flag = false;
                      if (!amountErrorRuleMap.has(index)) {
                        amountErrorRuleMap.set(index, rule);
                      }
                      console.log("超出该品类金额限制");
                    } else {
                      amountErrorRuleMap.delete(index);
                    }
                    console.log(amountErrorRuleMap);
                  } else if (rule.key_type == 15) {
                    //周期内总数量
                    if (item.qty > Number(rule.value)) {
                      flag = false;
                      if (!pcsErrorRuleMap.has(index)) {
                        pcsErrorRuleMap.set(index, rule);
                      }
                      console.log("超出该品类数量限制");
                    } else {
                      pcsErrorRuleMap.delete(index);
                    }
                    console.log(pcsErrorRuleMap);
                  } else if (rule.key_type == 21) {
                    //每单最小金额限制
                    if (
                      item.qty * Number(item.original_price) <
                      Number(rule.value)
                    ) {
                      flag = false;
                      item.minAmountErrorRule = rule;
                      console.log("未达到品牌最小金额");
                    } else {
                      item.minAmountErrorRule = null;
                    }
                    console.log(item.minAmountErrorRule);
                  } else if (rule.key_type == 25) {
                    //周期内SKU数量限制
                    let skuQty = 0;
                    Object.keys(rule.skus).forEach((key) => {
                      if (item.sku_id == key) {
                        skuQty =
                          (rule.skus[key] ? parseInt(rule.skus[key]) : 0) +
                          item.qty;
                      }
                    });
                    if (skuQty > Number(rule.value)) {
                      flag = false;
                      if (!skuPcsErrorRuleMap.has(index)) {
                        skuPcsErrorRuleMap.set(
                          index,
                          Object.assign({ sku: item.sku_id }, rule)
                        );
                      }
                      console.log("超出该SKU数量限制");
                    } else {
                      skuPcsErrorRuleMap.delete(index);
                    }
                    console.log(skuPcsErrorRuleMap);
                  } else if (rule.key_type == 30) {
                    //每单中SKU数量限制
                    if (item.qty > Number(rule.value)) {
                      flag = false;
                      item.orderSkuPcsErrorRule = rule;
                      console.log("超出本单该SKU数量限制");
                    } else {
                      item.orderSkuPcsErrorRule = null;
                    }
                    console.log(item.orderSkuPcsErrorRule);
                  }
                }
              });
              item.amountErrorRule.splice(
                0,
                item.amountErrorRule.length,
                ...Array.from(amountErrorRuleMap.values())
              );
              item.pcsErrorRule.splice(
                0,
                item.pcsErrorRule.length,
                ...Array.from(pcsErrorRuleMap.values())
              );
              item.skuPcsErrorRule.splice(
                0,
                item.skuPcsErrorRule.length,
                ...Array.from(skuPcsErrorRuleMap.values())
              );
            }
          } else {
            item.amountErrorRule = [];
            item.pcsErrorRule = [];
            item.minAmountErrorRule = null;
            item.skuPcsErrorRule = [];
            item.orderSkuPcsErrorRule = null;
          }
        });
        merchantItem.selectedQty = selectedQty;
        merchantItem.selectedSubtotal = selectedSubtotal;
        if (merchantItem.isChecked || hasSubChecked) {
          if (rules.length > 0) {
            const brandAmountErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const categoryAmountErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const totalAmountErrorRuleMap = new Map<
              number,
              OrderAssginMerchantOrderTableRule
            >([]);
            const brandPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const categoryPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const pcsErrorRuleMap = new Map<
              number,
              OrderAssginMerchantOrderTableRule
            >([]);
            const brandMinAmountErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const categoryMinAmountErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const brandSkuPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const categorySkuPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const skuPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const brandOrderSkuPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const categoryOrderSkuPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            const orderSkuPcsErrorRuleMap = new Map<
              string,
              OrderAssginMerchantOrderTableRule
            >([]);
            rules.forEach((rule, index) => {
              if (rule.key_type == 10) {
                //周期内总金额限制
                if (rule.brand !== "" && rule.category === "") {
                  //周期内同品牌金额限制
                  brandMap.forEach((value, key) => {
                    if (rule.brand == key) {
                      if (value.amount > Number(rule.value)) {
                        flag = false;
                        if (!brandAmountErrorRuleMap.has(key)) {
                          brandAmountErrorRuleMap.set(key, rule);
                        }
                        console.log("超出品牌金额限制");
                      } else {
                        brandAmountErrorRuleMap.delete(key);
                      }
                    }
                  });
                  console.log(brandAmountErrorRuleMap);
                } else if (rule.brand === "" && rule.category !== "") {
                  //周期内同分类金额限制
                  categoryMap.forEach((value, key) => {
                    if (rule.category == key) {
                      if (value.amount > Number(rule.value)) {
                        flag = false;
                        if (!categoryAmountErrorRuleMap.has(key)) {
                          categoryAmountErrorRuleMap.set(key, rule);
                        }
                        console.log("超出分类金额限制");
                      } else {
                        categoryAmountErrorRuleMap.delete(key);
                      }
                    }
                  });
                  console.log(categoryAmountErrorRuleMap);
                } else if (rule.brand === "" && rule.category === "") {
                  //周期内全品类金额限制
                  if (merchantItem.selectedSubtotal > Number(rule.value)) {
                    flag = false;
                    if (!totalAmountErrorRuleMap.has(index)) {
                      totalAmountErrorRuleMap.set(index, rule);
                    }
                    console.log("超出全品类金额限制");
                  } else {
                    totalAmountErrorRuleMap.delete(index);
                  }
                  console.log(totalAmountErrorRuleMap);
                }
              } else if (rule.key_type == 15) {
                //周期内总数量
                if (rule.brand !== "" && rule.category === "") {
                  //周期内同品牌数量限制
                  brandMap.forEach((value, key) => {
                    if (rule.brand == key) {
                      if (value.pcs > Number(rule.value)) {
                        flag = false;
                        if (!brandPcsErrorRuleMap.has(key)) {
                          brandPcsErrorRuleMap.set(key, rule);
                        }
                        console.log("超出品牌数量限制");
                      } else {
                        brandPcsErrorRuleMap.delete(key);
                      }
                    }
                  });
                  console.log(brandPcsErrorRuleMap);
                } else if (rule.brand === "" && rule.category !== "") {
                  //周期内同分类数量限制
                  categoryMap.forEach((value, key) => {
                    if (rule.category == key) {
                      if (value.pcs > Number(rule.value)) {
                        flag = false;
                        if (!categoryPcsErrorRuleMap.has(key)) {
                          categoryPcsErrorRuleMap.set(key, rule);
                        }
                        console.log("超出分类数量限制");
                      } else {
                        categoryPcsErrorRuleMap.delete(key);
                      }
                    }
                  });
                  console.log(categoryPcsErrorRuleMap);
                } else if (rule.brand === "" && rule.category === "") {
                  //周期内全品类数量限制
                  if (merchantItem.selectedQty > Number(rule.value)) {
                    flag = false;
                    if (!pcsErrorRuleMap.has(index)) {
                      pcsErrorRuleMap.set(index, rule);
                    }
                    console.log("超出总数量限制");
                  } else {
                    pcsErrorRuleMap.delete(index);
                  }
                  console.log(pcsErrorRuleMap);
                }
              } else if (rule.key_type == 21) {
                //每单最小金额限制
                if (rule.brand !== "" && rule.category === "") {
                  //每单同品牌最小金额限制
                  brandMap.forEach((value, key) => {
                    if (rule.brand == key) {
                      if (value.amount < Number(rule.value)) {
                        flag = false;
                        if (!brandMinAmountErrorRuleMap.has(key)) {
                          brandMinAmountErrorRuleMap.set(key, rule);
                        }
                        console.log("未达到品牌最小金额");
                      } else {
                        brandMinAmountErrorRuleMap.delete(key);
                      }
                    }
                  });
                  console.log(brandMinAmountErrorRuleMap);
                } else if (rule.brand === "" && rule.category !== "") {
                  //每单同分类最小金额限制
                  categoryMap.forEach((value, key) => {
                    if (rule.category == key) {
                      if (value.amount < Number(rule.value)) {
                        flag = false;
                        if (!categoryMinAmountErrorRuleMap.has(key)) {
                          categoryMinAmountErrorRuleMap.set(key, rule);
                        }
                        console.log("未达到分类最小金额");
                      } else {
                        categoryMinAmountErrorRuleMap.delete(key);
                      }
                    }
                  });
                  console.log(categoryMinAmountErrorRuleMap);
                } else if (rule.brand === "" && rule.category === "") {
                  //每单内全品类最小金额限制
                  if (merchantItem.selectedSubtotal < Number(rule.value)) {
                    flag = false;
                    merchantItem.orderMinAmountErrorRule = rule;
                    console.log("未达到全品类最小金额");
                  } else {
                    merchantItem.orderMinAmountErrorRule = null;
                  }
                  console.log(merchantItem.orderMinAmountErrorRule);
                }
              } else if (rule.key_type == 25) {
                //周期内同一SKU数量限制
                if (rule.brand !== "" && rule.category === "") {
                  //周期内同品牌SKU数量限制
                  brandMap.forEach((value, key) => {
                    if (rule.brand == key) {
                      value.skuPcs.forEach((v, k) => {
                        let skuQty =
                          (rule.skus[k] ? parseInt(rule.skus[k]) : 0) + v;
                        if (skuQty > Number(rule.value)) {
                          flag = false;
                          if (!brandSkuPcsErrorRuleMap.has(k)) {
                            brandSkuPcsErrorRuleMap.set(
                              k,
                              Object.assign({ sku: k }, rule)
                            );
                          }
                          console.log("超出品牌SKU数量限制");
                        } else {
                          //brandSkuPcsErrorRuleMap.delete(k);
                        }
                      });
                    }
                  });
                  console.log(brandSkuPcsErrorRuleMap);
                } else if (rule.brand === "" && rule.category !== "") {
                  //周期内同分类SKU数量限制
                  categoryMap.forEach((value, key) => {
                    if (rule.category == key) {
                      value.skuPcs.forEach((v, k) => {
                        let skuQty =
                          (rule.skus[k] ? parseInt(rule.skus[k]) : 0) + v;

                        if (skuQty > Number(rule.value)) {
                          flag = false;
                          if (!categorySkuPcsErrorRuleMap.has(k)) {
                            categorySkuPcsErrorRuleMap.set(
                              k,
                              Object.assign({ sku: k }, rule)
                            );
                          }
                        } else {
                          //categorySkuPcsErrorRuleMap.delete(k);
                        }
                      });
                    }
                  });
                  console.log(categorySkuPcsErrorRuleMap);
                } else if (rule.brand === "" && rule.category === "") {
                  //周期内全品类SKU数量限制
                  skuPcsMap.forEach((value, key) => {
                    let skuQty =
                      (rule.skus[key] ? parseInt(rule.skus[key]) : 0) + value;
                    if (skuQty > Number(rule.value)) {
                      flag = false;
                      if (!skuPcsErrorRuleMap.has(key)) {
                        skuPcsErrorRuleMap.set(
                          key,
                          Object.assign({ sku: key }, rule)
                        );
                      }
                      console.log("超出全品类SKU数量限制");
                    } else {
                      //skuPcsErrorRuleMap.delete(key);
                    }
                  });
                  console.log(skuPcsErrorRuleMap);
                }
              } else if (rule.key_type == 30) {
                //每单中同一SKU数量限制
                if (rule.brand !== "" && rule.category === "") {
                  //每单中同品牌SKU数量限制
                  brandMap.forEach((value, key) => {
                    if (rule.brand == key) {
                      value.skuPcs.forEach((v, k) => {
                        if (v > Number(rule.value)) {
                          flag = false;
                          if (!brandOrderSkuPcsErrorRuleMap.has(k)) {
                            brandOrderSkuPcsErrorRuleMap.set(
                              k,
                              Object.assign({ sku: k }, rule)
                            );
                          }
                          console.log("超出本单品牌SKU数量限制");
                        } else {
                          brandOrderSkuPcsErrorRuleMap.delete(k);
                        }
                      });
                    }
                  });
                  console.log(brandOrderSkuPcsErrorRuleMap);
                } else if (rule.brand === "" && rule.category !== "") {
                  //每单中同分类SKU数量限制
                  categoryMap.forEach((value, key) => {
                    if (rule.category == key) {
                      value.skuPcs.forEach((v, k) => {
                        if (v > Number(rule.value)) {
                          flag = false;
                          if (!categoryOrderSkuPcsErrorRuleMap.has(k)) {
                            categoryOrderSkuPcsErrorRuleMap.set(
                              k,
                              Object.assign({ sku: k }, rule)
                            );
                          }
                          console.log("超出品牌SKU数量限制");
                        } else {
                          categoryOrderSkuPcsErrorRuleMap.delete(k);
                        }
                      });
                    }
                  });
                  console.log(categoryOrderSkuPcsErrorRuleMap);
                } else if (rule.brand === "" && rule.category === "") {
                  //每单中全品类SKU数量限制
                  skuPcsMap.forEach((value, key) => {
                    if (value > Number(rule.value)) {
                      flag = false;
                      if (!orderSkuPcsErrorRuleMap.has(key)) {
                        orderSkuPcsErrorRuleMap.set(
                          key,
                          Object.assign({ sku: key }, rule)
                        );
                      }
                      console.log("超出本单同一SKU数量限制");
                    } else {
                      orderSkuPcsErrorRuleMap.delete(key);
                    }
                  });
                  console.log(orderSkuPcsErrorRuleMap);
                }
              }
            });
            merchantItem.brandAmountErrorRule.splice(
              0,
              merchantItem.brandAmountErrorRule.length,
              ...Array.from(brandAmountErrorRuleMap.values())
            );
            merchantItem.categoryAmountErrorRule.splice(
              0,
              merchantItem.categoryAmountErrorRule.length,
              ...Array.from(categoryAmountErrorRuleMap.values())
            );
            merchantItem.totalAmountErrorRule.splice(
              0,
              merchantItem.totalAmountErrorRule.length,
              ...Array.from(totalAmountErrorRuleMap.values())
            );
            merchantItem.brandPcsErrorRule.splice(
              0,
              merchantItem.brandPcsErrorRule.length,
              ...Array.from(brandPcsErrorRuleMap.values())
            );
            merchantItem.categoryPcsErrorRule.splice(
              0,
              merchantItem.categoryPcsErrorRule.length,
              ...Array.from(categoryPcsErrorRuleMap.values())
            );
            merchantItem.pcsErrorRule.splice(
              0,
              merchantItem.pcsErrorRule.length,
              ...Array.from(pcsErrorRuleMap.values())
            );
            merchantItem.brandMinAmountErrorRule.splice(
              0,
              merchantItem.brandMinAmountErrorRule.length,
              ...Array.from(brandMinAmountErrorRuleMap.values())
            );
            merchantItem.categoryMinAmountErrorRule.splice(
              0,
              merchantItem.categoryMinAmountErrorRule.length,
              ...Array.from(categoryMinAmountErrorRuleMap.values())
            );
            merchantItem.brandSkuPcsErrorRule.splice(
              0,
              merchantItem.brandSkuPcsErrorRule.length,
              ...Array.from(brandSkuPcsErrorRuleMap.values())
            );
            merchantItem.categorySkuPcsErrorRule.splice(
              0,
              merchantItem.categorySkuPcsErrorRule.length,
              ...Array.from(categorySkuPcsErrorRuleMap.values())
            );
            merchantItem.skuPcsErrorRule.splice(
              0,
              merchantItem.skuPcsErrorRule.length,
              ...Array.from(skuPcsErrorRuleMap.values())
            );
            merchantItem.brandOrderSkuPcsErrorRule.splice(
              0,
              merchantItem.brandOrderSkuPcsErrorRule.length,
              ...Array.from(brandOrderSkuPcsErrorRuleMap.values())
            );
            merchantItem.categoryOrderSkuPcsErrorRule.splice(
              0,
              merchantItem.categoryOrderSkuPcsErrorRule.length,
              ...Array.from(categoryOrderSkuPcsErrorRuleMap.values())
            );
            merchantItem.orderSkuPcsErrorRule.splice(
              0,
              merchantItem.orderSkuPcsErrorRule.length,
              ...Array.from(orderSkuPcsErrorRuleMap.values())
            );
          }
        } else {
          brandMap.clear();
          categoryMap.clear();
          skuPcsMap.clear();
          merchantItem.totalAmountErrorRule = [];
          merchantItem.brandAmountErrorRule = [];
          merchantItem.categoryAmountErrorRule = [];
          merchantItem.orderMinAmountErrorRule = null;
          merchantItem.brandMinAmountErrorRule = [];
          merchantItem.categoryMinAmountErrorRule = [];
          merchantItem.pcsErrorRule = [];
          merchantItem.brandPcsErrorRule = [];
          merchantItem.categoryPcsErrorRule = [];
          merchantItem.skuPcsErrorRule = [];
          merchantItem.brandSkuPcsErrorRule = [];
          merchantItem.categorySkuPcsErrorRule = [];
          merchantItem.orderSkuPcsErrorRule = [];
          merchantItem.brandOrderSkuPcsErrorRule = [];
          merchantItem.categoryOrderSkuPcsErrorRule = [];
        }
      });
      console.log("============Check Rules End============");
      return flag;
    };

    const getDuration = (val) => {
      let map: Map<number, string> = new Map([
        [10, t("orderAssign.daily")],
        [20, t("orderAssign.monthly")],
        [30, t("orderAssign.weekly")],
        [50, t("orderAssign.halfYear")],
        [60, t("orderAssign.quarter")],
      ]);
      return map.get(val);
    };

    const skuItemPcsHasError = (
      skuItem: OrderAssginMerchantOrderSubTable,
      pcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      skuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      orderSkuPcsErrorRule: OrderAssginMerchantOrderTableRule | null,
      brandPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      allSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      brandSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categorySkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      orderAllSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      brandOrderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryOrderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>
    ) => {
      let skuPcsIndex = skuPcsErrorRule.findIndex((rule) => {
        return skuItem.sku_id == rule.sku;
      });
      let brandPcsIndex = brandPcsErrorRule.findIndex((rule) => {
        return skuItem.brand == rule.brand;
      });
      let categoryPcsIndex = categoryPcsErrorRule.findIndex((rule) => {
        return skuItem.category.includes(rule.category);
      });
      let allSkuPcsIndex = allSkuPcsErrorRule.findIndex((rule) => {
        return skuItem.sku_id == rule.sku;
      });
      let brandSkuPcsIndex = brandSkuPcsErrorRule.findIndex((rule) => {
        return skuItem.brand == rule.brand && skuItem.sku_id == rule.sku;
      });
      let categorySkuPcsIndex = categorySkuPcsErrorRule.findIndex((rule) => {
        return (
          skuItem.category.includes(rule.category) && skuItem.sku_id == rule.sku
        );
      });
      let orderAllSkuPcsIndex = orderAllSkuPcsErrorRule.findIndex((rule) => {
        return skuItem.sku_id == rule.sku;
      });
      let brandOrderSkuPcsIndex = brandOrderSkuPcsErrorRule.findIndex(
        (rule) => {
          return skuItem.brand == rule.brand && skuItem.sku_id == rule.sku;
        }
      );
      let categoryOrderSkuPcsIndex = categoryOrderSkuPcsErrorRule.findIndex(
        (rule) => {
          return (
            skuItem.category.includes(rule.category) &&
            skuItem.sku_id == rule.sku
          );
        }
      );
      return (
        skuItem.isChecked &&
        (pcsErrorRule.length > 0 ||
          skuPcsIndex > -1 ||
          orderSkuPcsErrorRule ||
          brandPcsIndex > -1 ||
          categoryPcsIndex > -1 ||
          allSkuPcsIndex > -1 ||
          brandSkuPcsIndex > -1 ||
          categorySkuPcsIndex > -1 ||
          orderAllSkuPcsIndex > -1 ||
          brandOrderSkuPcsIndex > -1 ||
          categoryOrderSkuPcsIndex > -1)
      );
    };

    const skuItemAmountHasError = (
      skuItem: OrderAssginMerchantOrderSubTable,
      amountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      minAmountErrorRule: OrderAssginMerchantOrderTableRule | null,
      brandAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      brandMinAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryMinAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>
    ) => {
      let brandAmountIndex = brandAmountErrorRule.findIndex((rule) => {
        return skuItem.brand == rule.brand;
      });
      let categoryAmountIndex = categoryAmountErrorRule.findIndex((rule) => {
        return skuItem.category.includes(rule.category);
      });
      let brandMinAmountIndex = brandMinAmountErrorRule.findIndex((rule) => {
        return skuItem.brand == rule.brand;
      });
      let categoryMinAmountIndex = categoryMinAmountErrorRule.findIndex(
        (rule) => {
          return skuItem.category.includes(rule.category);
        }
      );
      return (
        skuItem.isChecked &&
        (amountErrorRule.length > 0 ||
          minAmountErrorRule ||
          brandAmountIndex > -1 ||
          categoryAmountIndex > -1 ||
          brandMinAmountIndex > -1 ||
          categoryMinAmountIndex > -1)
      );
    };

    const getSkuItemPcsErrorTips = (
      skuItem: OrderAssginMerchantOrderSubTable,
      pcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      skuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      orderSkuPcsErrorRule: OrderAssginMerchantOrderTableRule | null,
      brandPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      allSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      brandSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categorySkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      orderAllSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      brandOrderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryOrderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>
    ) => {
      let tpl = "";
      if (pcsErrorRule.length > 0) {
        pcsErrorRule.forEach((rule) => {
          tpl += `<p class="m-0">${t("orderAssign.brandCategoryPcsLimitTip", [
            getDuration(rule?.duration),
            parseInt(rule?.value),
          ])}</p>`;
        });
      }
      if (skuPcsErrorRule.length > 0) {
        skuPcsErrorRule.forEach((rule) => {
          if (skuItem.sku_id == rule.sku) {
            tpl += `<p class="m-0">${t(
              "orderAssign.brandCategorySkuPcsLimitTip",
              [getDuration(rule?.duration), parseInt(rule?.value)]
            )}</p>`;
          }
        });
      }
      if (orderSkuPcsErrorRule) {
        tpl += `<p class="m-0">${t(
          "orderAssign.brandCategoryOrderSkuPcsLimitTip",
          [parseInt(orderSkuPcsErrorRule?.value)]
        )}</p>`;
      }
      if (brandPcsErrorRule.length > 0) {
        brandPcsErrorRule.forEach((rule) => {
          if (skuItem.brand == rule.brand) {
            tpl += `<p class="m-0">${t("orderAssign.brandPcsLimitTip", [
              getDuration(rule?.duration),
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (categoryPcsErrorRule.length > 0) {
        categoryPcsErrorRule.forEach((rule) => {
          if (skuItem.category.includes(rule.category)) {
            tpl += `<p class="m-0">${t("orderAssign.categoryPcsLimitTip", [
              getDuration(rule?.duration),
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (allSkuPcsErrorRule.length > 0) {
        allSkuPcsErrorRule.forEach((rule) => {
          if (skuItem.sku_id == rule.sku) {
            tpl += `<p class="m-0">${t("orderAssign.allSkuPcsLimitTip", [
              getDuration(rule?.duration),
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (brandSkuPcsErrorRule.length > 0) {
        brandSkuPcsErrorRule.forEach((rule) => {
          if (skuItem.brand == rule.brand && skuItem.sku_id == rule.sku) {
            tpl += `<p class="m-0">${t("orderAssign.brandSkuPcsLimitTip", [
              getDuration(rule?.duration),
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (categorySkuPcsErrorRule.length > 0) {
        categorySkuPcsErrorRule.forEach((rule) => {
          if (
            skuItem.category.includes(rule.category) &&
            skuItem.sku_id == rule.sku
          ) {
            tpl += `<p class="m-0">${t("orderAssign.categorySkuPcsLimitTip", [
              getDuration(rule?.duration),
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (orderAllSkuPcsErrorRule.length > 0) {
        orderAllSkuPcsErrorRule.forEach((rule) => {
          if (skuItem.sku_id == rule.sku) {
            tpl += `<p class="m-0">${t("orderAssign.allOrderSkuPcsLimitTip", [
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (brandOrderSkuPcsErrorRule.length > 0) {
        brandOrderSkuPcsErrorRule.forEach((rule) => {
          if (skuItem.brand == rule.brand && skuItem.sku_id == rule.sku) {
            tpl += `<p class="m-0">${t("orderAssign.brandOrderSkuPcsLimitTip", [
              parseInt(rule?.value),
            ])}</p>`;
          }
        });
      }
      if (categoryOrderSkuPcsErrorRule.length > 0) {
        categoryOrderSkuPcsErrorRule.forEach((rule) => {
          if (
            skuItem.category.includes(rule.category) &&
            skuItem.sku_id == rule.sku
          ) {
            tpl += `<p class="m-0">${t(
              "orderAssign.categoryOrderSkuPcsLimitTip",
              [parseInt(rule?.value)]
            )}</p>`;
          }
        });
      }
      return tpl;
    };

    const getSkuItemAmountErrorTips = (
      skuItem: OrderAssginMerchantOrderSubTable,
      amountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      minAmountErrorRule: OrderAssginMerchantOrderTableRule | null,
      brandAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      brandMinAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>,
      categoryMinAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>
    ) => {
      let tpl = "";
      if (amountErrorRule.length > 0) {
        amountErrorRule.forEach((rule) => {
          tpl += `<p class="m-0">${t(
            "orderAssign.brandCategoryAmountLimitTip",
            [
              getDuration(rule?.duration),
              accounting.formatMoney(rule?.value, {
                symbol: "€ ",
                precision: 2,
                thousand: ".",
                decimal: ",",
              }),
            ]
          )}</p>`;
        });
      }
      if (minAmountErrorRule) {
        tpl += `<p class="m-0">${t(
          "orderAssign.brandCategoryMinAmountLimitTip",
          [
            accounting.formatMoney(minAmountErrorRule?.value, {
              symbol: "€ ",
              precision: 2,
              thousand: ".",
              decimal: ",",
            }),
          ]
        )}</p>`;
      }
      if (brandAmountErrorRule.length > 0) {
        brandAmountErrorRule.forEach((rule) => {
          if (skuItem.brand == rule.brand) {
            tpl += `<p class="m-0">${t("orderAssign.brandAmountLimitTip", [
              getDuration(rule?.duration),
              accounting.formatMoney(rule?.value, {
                symbol: "€ ",
                precision: 2,
                thousand: ".",
                decimal: ",",
              }),
            ])}</p>`;
          }
        });
      }
      if (categoryAmountErrorRule.length > 0) {
        categoryAmountErrorRule.forEach((rule) => {
          if (skuItem.category.includes(rule.category)) {
            tpl += `<p class="m-0">${t("orderAssign.categoryAmountLimitTip", [
              getDuration(rule?.duration),
              accounting.formatMoney(rule?.value, {
                symbol: "€ ",
                precision: 2,
                thousand: ".",
                decimal: ",",
              }),
            ])}</p>`;
          }
        });
      }
      if (brandMinAmountErrorRule.length > 0) {
        brandMinAmountErrorRule.forEach((rule) => {
          if (skuItem.brand == rule.brand) {
            tpl += `<p class="m-0">${t("orderAssign.brandMinAmountLimitTip", [
              accounting.formatMoney(rule?.value, {
                symbol: "€ ",
                precision: 2,
                thousand: ".",
                decimal: ",",
              }),
            ])}</p>`;
          }
        });
      }
      if (categoryMinAmountErrorRule.length > 0) {
        categoryMinAmountErrorRule.forEach((rule) => {
          if (skuItem.category.includes(rule.category)) {
            tpl += `<p class="m-0">${t(
              "orderAssign.categoryMinAmountLimitTip",
              [
                accounting.formatMoney(rule?.value, {
                  symbol: "€ ",
                  precision: 2,
                  thousand: ".",
                  decimal: ",",
                }),
              ]
            )}</p>`;
          }
        });
      }
      return tpl;
    };

    const getSubmitData = () => {
      const fulfillList: Array<OrderAssginMerchantOrderFulfilledList> = [];
      formData.value.items.forEach((merchantItem) => {
        merchantItem.items.forEach((item) => {
          if (item.isChecked) {
            fulfillList.push({
              channel: merchantItem.channel_id,
              fulfill_ids: item.fulfill_ids,
              qty: item.qty,
              sku_id: item.sku_id,
            });
          }
        });
      });
      return fulfillList;
    };

    const expandSub = (item: OrderAssginMerchantOrderTable) => {
      item.isExpand = !item.isExpand;
    };

    const handleFilter = (val) => {
      formData.value.filterOptions = val;
      formData.value.ids = [];
      getFormInfo();
    };

    const handleReset = () => {
      formData.value.ids = [];
      getFormInfo();
    };

    const getFilterGroup = computed(() => {
      let filterArr: Array<FilterOption> = [];
      if (formData.value.filterOptions.channel.length !== 0) {
        filterArr.push({
          field: "channel",
          value: formData.value.filterOptions.channel,
          condition: "in",
        });
      }
      if (formData.value.filterOptions.merchant != "") {
        filterArr.push({
          field: "merchant",
          value: formData.value.filterOptions.merchant,
          condition: "eq",
        });
      }
      if (props.store_id) {
        filterArr.push({
          field: "store_id",
          value: props.store_id.split(","),
          condition: "in",
        });
      }
      return filterArr;
    });

    const isDisabledSubmit = computed(() => {
      let isFlag = false;
      if (formData.value.ids.length === 0) {
        return true;
      }
      return isFlag;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          if (!checkRules()) {
            Swal.fire({
              text: t("fulfillmentOrder.ruleCheckTip"),
              icon: "question",
              showCancelButton: true,
              buttonsStyling: false,
              confirmButtonText: t("fulfillmentOrder.buttonYes"),
              cancelButtonText: t("fulfillmentOrder.buttonNo"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                submitRequest();
              }
            });
          } else {
            submitRequest();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitRequest = async () => {
      if (loading.value) return;
      loading.value = true;
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      const { data } = await ApiOrderAssign.storeRequestFulfillment({
        fulfilled_list: getSubmitData(),
      });
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          getFormInfo();
          emit("update-list");
          hideModal(merchantOrderList.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getShowInfo = async () => {
      const { data } = await ApiOrderAssign.getRequestFulfillmentList({
        filter_group: getFilterGroup.value,
      });
      if (data.code == 0) {
        let items: OrderAssginMerchantOrderTable[] = Object.values(
          data.data.data_list
        );
        items.forEach((item: OrderAssginMerchantOrderTable) => {
          item.selectedSubtotal = 0;
          item.selectedQty = 0;
          item.isChecked = false;
          item.isExpand = true;
          item.fulfill_ids = new Map<
            number[],
            OrderAssginMerchantOrderSubTable
          >([]);
          item.items = Object.values(item.items);
          item.items.forEach((row) => {
            row.isChecked = false;
            row.max_qty = row.qty;
            row.amountErrorRule = [];
            row.pcsErrorRule = [];
            row.minAmountErrorRule = null;
            row.skuPcsErrorRule = [];
            row.orderSkuPcsErrorRule = null;
          });
          item.totalAmountErrorRule = [];
          item.brandAmountErrorRule = [];
          item.categoryAmountErrorRule = [];
          item.orderMinAmountErrorRule = null;
          item.brandMinAmountErrorRule = [];
          item.categoryMinAmountErrorRule = [];
          item.pcsErrorRule = [];
          item.brandPcsErrorRule = [];
          item.categoryPcsErrorRule = [];
          item.skuPcsErrorRule = [];
          item.brandSkuPcsErrorRule = [];
          item.categorySkuPcsErrorRule = [];
          item.orderSkuPcsErrorRule = [];
          item.brandOrderSkuPcsErrorRule = [];
          item.categoryOrderSkuPcsErrorRule = [];
        });
        formData.value.items = items;
        formData.value.channel_number = data.data.channel_number;
        formData.value.sku_number = data.data.sku_number;
        formData.value.merchant = data.data.filter_options.merchants;
        formData.value.channelOption = data.data.filter_options.channels;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const debounceSearch = _.debounce(getFormInfo, 1000);

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(merchantOrderList.value, () => {
        getFormInfo();
      });
      modalHideListener(merchantOrderList.value, () => {
        resetForm();
      });
      cancelReadonly(false);
    });

    return {
      t,
      formatDate,
      formatDateTime,
      commonGalleryImages,
      InventoryRuleKey,
      props,
      merchantOrderList,
      loading,
      formData,
      submitButton,
      formRef,
      options,
      rules,
      cancelReadonly,
      // filterOptions,
      toggleItemSelect,
      toggleSubSelect,
      handleQtyChange,
      getDuration,
      skuItemPcsHasError,
      getSkuItemPcsErrorTips,
      skuItemAmountHasError,
      getSkuItemAmountErrorTips,
      expandSub,
      isDisabledSubmit,
      submit,
      resetForm,
      handleFilter,
      handleReset,
      getShowInfo,
      getSalesChannelsData,
    };
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "order-tracking-icon",
  props: {
    value: {
      type: String,
      default: () => {
        return "";
      },
    },
    node: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  setup() {
    return {};
  },
});


import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import moment from "moment";
import {
  ChannelOption,
  MerchantOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { ApiBase, ApiMerchant } from "@/core/api";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";
import {
  commonChangeFilterAddDate,
  removeReadonly,
} from "@/core/directive/function/common";
import WindowResize from "@/components/layout/WindowResize.vue";
import { useRoute } from "vue-router";

interface Filter {
  status: string[];
  fulfillment_status: string[];
  merchant: string;
  channel: string[];
  order_number: string;
  brand_name: string;
  merchant_article_no: string;
  definite_inventory_source: string[];
  ean: string;
  order_tagging: string[];
  order_type: string[];
  brand_article_no: string;
  order_date_start: string;
  order_date_end: string;
  business_date_start: string;
  business_date_end: string;
  inventory_confirm_date_start: string;
  inventory_confirm_date_end: string;
}

export default defineComponent({
  name: "mb-order-routing-routing-overview-filter-dropdown",
  components: { CommonChannelOption, WindowResize },
  emits: ["submit-filter", "reset-filter"],
  props: {
    orderStatus: {
      type: Array as () => TaggingItem[],
      default: () => {
        return [];
      },
    },
    fulfillmentStatus: {
      type: Array as () => TaggingItem[],
      default: () => {
        return [];
      },
    },
    store_id: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const currentInstance: any = getCurrentInstance();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      status: [],
      fulfillment_status: [],
      merchant: "",
      channel: [],
      order_number: "",
      brand_name: "",
      merchant_article_no: "",
      definite_inventory_source: [],
      ean: "",
      order_tagging: [],
      order_type: [],
      brand_article_no: "",
      order_date_start: "",
      order_date_end: "",
      business_date_start: "",
      business_date_end: "",
      inventory_confirm_date_start: commonChangeFilterAddDate(
        moment(),
        -1,
        "months"
      ),
      inventory_confirm_date_end: commonChangeFilterAddDate(
        moment(),
        14,
        "days"
      ),
    });

    const options = ref({
      channel: [] as ChannelOption[],
      channelLoading: false,
      merchant: [] as MerchantOption[],
      merchantLoading: false,
      definite_inventory_source: [] as TaggingItem[],
      order_type: [] as TaggingItem[],
      order_tagging: [] as TaggingItem[],
    });

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "definite_inventory_source",
          "order_tagging",
          "draft_order_store_type",
        ],
      });
      if (data.code == 0) {
        options.value.definite_inventory_source =
          data.data.definite_inventory_source.items;
        options.value.definite_inventory_source.push({
          label: t("orderAssign.inventoryAggregation"),
          value: "0",
        });
        options.value.order_type = data.data.draft_order_store_type.items;
        options.value.order_tagging = data.data.order_tagging.items;
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (id?, remake?) => {
      if (remake) {
        formData.value.channel = [];
        options.value.channel = [];
      }
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const isDoubleSale = computed(() => {
      return route.path.includes("double-sale-order");
    });

    onMounted(() => {
      getTaggingData();
      getMerchantSourceData("");
      cancelReadonly(false);
    });

    return {
      t,
      props,
      formData,
      formRef,
      options,
      cancelReadonly,
      getSalesChannelsData,
      submit,
      handleReset,
      searchMerchantSourceItems,
      isDoubleSale,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "flex-1 d-flex align-items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "w-75px text-end"
}
const _hoisted_5 = {
  key: 1,
  class: "w-150px text-gray-400 text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.profitRateChannelOption?.channel_name), 1),
      (_ctx.profitRateChannelOption?.show_total_commission)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, "（" + _toDisplayString(_ctx.profitRateChannelOption?.show_total_commission) + "）", 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.profitRateChannelOption?.show_profit_rate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass({
          'text-primary': Number(_ctx.profitRateChannelOption.profit_rate) >= 0,
          'text-danger': Number(_ctx.profitRateChannelOption.profit_rate) < 0,
        })
          }, _toDisplayString(_ctx.profitRateChannelOption?.show_profit_rate), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.profitRateChannelOption?.channel_type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.profitRateChannelOption.channel_type), 1))
      : _createCommentVNode("", true)
  ]))
}
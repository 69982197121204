
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiOrderAssign } from "@/core/api";
import {
  getFulfillmentSourceMap,
  getFulfillmentTextStatus,
} from "@/core/directive/function/fulfillmentOrder";
import mixin from "@/mixins";
import { FulfillmentOrderStatus } from "@/core/directive/type/fulfillmentOrder";
import { formatDate } from "@/core/directive/function/common";
import { OrderRequestProgress } from "@/core/directive/type/orderRequest";
import { CommonDateType } from "@/core/directive/type/common";
import PermissionCommon from "../layout/PermissionCommon.vue";
import { isMB } from "@/core/directive/function/user";

export default defineComponent({
  components: { PermissionCommon },
  name: "kt-order-assign-item-table",
  props: {
    type: {
      type: Number,
      default: 0,
    },
    itemId: {
      type: Number,
      default: 0,
    },
    subId: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update-list", "reset-form", "refuse", "cancel"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();

    const loading = ref<boolean>(false);
    const base = ref("");
    const order = ref("");
    const isRequest = ref<boolean>(false);

    const formData = ref({
      items: [] as any[],
    });

    const options = ref({
      fulfillment_type: getFulfillmentSourceMap(t),
    });

    const getMerchantOrderRoute = computed(() => {
      return (item) => {
        if (item.order_progress >= OrderRequestProgress.Confirmed) {
          return "/merchant-order/order-processing/";
        }
        return "/merchant-order/order-request/";
      };
    });

    const customFormatDate = (date: CommonDateType, format = "YYYY.MM.DD") => {
      return formatDate(date, format);
    };

    const refuseItem = (id) => {
      emit("refuse", {
        order_id: props.itemId,
        fulfillment_order_id: id,
      });
      // Swal.fire({
      //   text: t("orderAssign.refuseTip"),
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: t("common.yes"),
      //   cancelButtonText: t("common.no"),
      //   customClass: {
      //     confirmButton: "btn btn-primary",
      //     cancelButton: "btn btn-danger",
      //   },
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     loading.value = true;
      //     const { data } = await ApiFulfillmentOrders.rejectFulfillmentOrder({
      //       id,
      //       notice: "",
      //     });
      //     loading.value = false;
      //     if (data.code == 0) {
      //       updateList();
      //     } else {
      //       showServerErrorMsg(data);
      //     }
      //   }
      // });
    };

    const cancelItem = (id) => {
      emit("cancel", {
        order_id: props.itemId,
        fulfillment_order_id: id,
      });
    };

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiOrderAssign.getOrderAssignItem({
        id: props.itemId,
      });
      loading.value = false;
      isRequest.value = true;
      if (data.code == 0) {
        formData.value.items = data.data.items;
      }
    };

    const statusClass = computed(() => {
      return (status: number) => {
        return getFulfillmentTextStatus(status);
      };
    });

    const updateList = () => {
      formData.value.items = [];
      isRequest.value = false;
      getShowInfo();
    };

    onMounted(() => {
      getShowInfo();
    });

    return {
      t,
      isMB,
      formatDate,
      customFormatDate,
      FulfillmentOrderStatus,
      props,
      loading,
      base,
      order,
      options,
      isRequest,
      formData,
      statusClass,
      getMerchantOrderRoute,
      refuseItem,
      cancelItem,
      updateList,
    };
  },
});


import { computed, defineComponent, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import { ApiOrderAssign } from "@/core/api";
import _ from "lodash";
import {
  orderAssginTable,
  OrderAssginTable,
} from "@/core/directive/interface/orderAssign";
import { modalShowListener } from "@/core/directive/function/common";

export default defineComponent({
  name: "mb-order-routing-routing-overview-cancel-open-qty",
  props: {
    tableItemData: {
      type: Object as () => OrderAssginTable,
      default: () => {
        return Object.assign({}, orderAssginTable);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const orderRoutingCancelOpenQtyRef = ref<CommonHtmlType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      quantity: 0,
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          submitRequest();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitRequest = async () => {
      if (loading.value) return;
      loading.value = true;
      if(!isDepositOrFullPaymentOrders()){
        const { data } = await ApiOrderAssign.cancelOrderAssignItem({
          item_id: props.tableItemData.id,
          open_qty: formData.value.quantity,
        });
        if (data.code == 0) {
          showFormSubmitSuccessMsg(() => {
            emit("update-list");
            hideModal(orderRoutingCancelOpenQtyRef.value);
          });
        } else {
          showServerErrorMsg(data);
        }
      } else {
        const { data } = await ApiOrderAssign.cancelOrderAssignItemV2({
          item_id: props.tableItemData.id,
          open_qty: formData.value.quantity,
        });
        if (data.code == 0) {
          showFormSubmitSuccessMsg(() => {
            emit("update-list");
            hideModal(orderRoutingCancelOpenQtyRef.value);
          });
        } else {
          showServerErrorMsg(data);
        }
      }

      loading.value = false;
    };

    const handleDiscard = () => {
      hideModal(orderRoutingCancelOpenQtyRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const init = () => {
      setTimeout(() => {
        formData.value.quantity = props.tableItemData.allow_cancel_qty;
      }, 0);
    };

    const isDepositOrFullPaymentOrders = () => {
      return props.tableItemData.store_id === 1 || props.tableItemData.store_id === 12 || props.tableItemData.store_id === 24;
    }

    onMounted(() => {
      modalShowListener(orderRoutingCancelOpenQtyRef.value, () => {
        init();
      });
      modalHideListener(orderRoutingCancelOpenQtyRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      orderRoutingCancelOpenQtyRef,
      loading,
      formData,
      formRef,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});

/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 15:27:36
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-05 16:41:41
 */
import { TaggingItem } from "../interface/common";
import {
  BadgeStyle,
  CommonLanguageType,
  NumberOrString,
  TextStyle,
} from "../type/common";
import {
  FulfillmentOrderStatus,
  FulfillmentSource,
} from "../type/fulfillmentOrder";

/**
 * @description: 获取履约状态（样式）
 * @return CommonStatus
 */
export const getFulfillmentStatus = (status: NumberOrString): string => {
  let statusClass = "";

  switch (status) {
    case FulfillmentOrderStatus.Initialized:
      statusClass = BadgeStyle.Warning;
      break;
    case FulfillmentOrderStatus.Assigning:
      statusClass = BadgeStyle.Primary;
      break;
    case FulfillmentOrderStatus.Assigned:
      statusClass = BadgeStyle.Primary;
      break;
    case FulfillmentOrderStatus.Approved:
      statusClass = BadgeStyle.Primary;
      break;
    case FulfillmentOrderStatus.Review:
      statusClass = BadgeStyle.Primary;
      break;
    case FulfillmentOrderStatus.Shipping:
      statusClass = BadgeStyle.Primary;
      break;
    case FulfillmentOrderStatus.Fulfilled:
      statusClass = BadgeStyle.Primary;
      break;
    case FulfillmentOrderStatus.Rejected:
      statusClass = BadgeStyle.Danger;
      break;
    case FulfillmentOrderStatus.Canceled:
      statusClass = BadgeStyle.Dark;
      break;
    case FulfillmentOrderStatus.OnHold:
      statusClass = BadgeStyle.Info;
      break;
    case FulfillmentOrderStatus.Completed:
      statusClass = BadgeStyle.Success;
      break;
    default:
      statusClass = BadgeStyle.Primary;
      break;
  }
  return statusClass;
};

export const getFulfillmentTextStatus = (status: NumberOrString): string => {
  let statusClass = "";
  switch (status) {
    case FulfillmentOrderStatus.Initialized:
      statusClass = TextStyle.Warning;
      break;
    case FulfillmentOrderStatus.Assigning:
      statusClass = TextStyle.Primary;
      break;
    case FulfillmentOrderStatus.Assigned:
      statusClass = TextStyle.Primary;
      break;
    case FulfillmentOrderStatus.Approved:
      statusClass = TextStyle.Success;
      break;
    case FulfillmentOrderStatus.Review:
      statusClass = TextStyle.Success;
      break;
    case FulfillmentOrderStatus.Shipping:
      statusClass = TextStyle.Success;
      break;
    case FulfillmentOrderStatus.Fulfilled:
      statusClass = TextStyle.Success;
      break;
    case FulfillmentOrderStatus.Rejected:
      statusClass = TextStyle.Danger;
      break;
    case FulfillmentOrderStatus.Canceled:
      statusClass = TextStyle.Dark;
      break;
    case FulfillmentOrderStatus.OnHold:
      statusClass = TextStyle.Info;
      break;
    case FulfillmentOrderStatus.Completed:
      statusClass = TextStyle.Success;
      break;
    default:
      statusClass = TextStyle.Primary;
      break;
  }
  return "text-uppercase " + statusClass;
};

/**
 * @description: 获取履约来源（radio）
 * @return TaggingItem[]
 */
export const getFulfillmentSourceRadio = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("fulfillmentOrder.fulfillmentSourceMerchant"),
      value: FulfillmentSource.Merchant,
    },
    {
      label: language("fulfillmentOrder.fulfillmentSourcePreStock"),
      value: FulfillmentSource.PreStock,
    },
  ];
};

/**
 * @description: 获取履约来源（map）
 * @return string
 */
export const getFulfillmentSourceMap = (
  language: CommonLanguageType
): Map<number, string> => {
  const result = new Map([
    [
      FulfillmentSource.Merchant,
      language("fulfillmentOrder.fulfillmentSourceMerchant"),
    ],
    [
      FulfillmentSource.PreStock,
      language("fulfillmentOrder.fulfillmentSourcePreStock"),
    ],
  ]);
  return result;
};


import store from "@/store";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "window-resize",
  setup() {
    const isMobile = ref(store.getters.isMobile);

    watch(
      () => store.getters.isMobile,
      (newValue) => {
        // console.log(newValue);
        isMobile.value = newValue;
      }
    );

    return {
      isMobile,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "flex-1 d-flex align-items-center" }
const _hoisted_3 = { class: "text-line-clamp-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "w-120px text-end"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.profitRateOption?.merchant_name), 1),
      (_ctx.profitRateOption?.show_total_commission)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", null, "（" + _toDisplayString(_ctx.profitRateOption?.show_total_commission) + "）", 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.profitRateOption?.show_profit_rate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (
          _ctx.profitRateOption?.profit_rate.min &&
          _ctx.profitRateOption?.profit_rate.min !==
            _ctx.profitRateOption?.profit_rate.max
        )
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass({
          'text-primary': Number(_ctx.profitRateOption?.profit_rate.min) >= 0,
          'text-danger': Number(_ctx.profitRateOption?.profit_rate.min) < 0,
        })
              }, _toDisplayString(Number(_ctx.profitRateOption?.profit_rate.min).toFixed(2)) + "% ", 3))
            : _createCommentVNode("", true),
          (
          _ctx.profitRateOption?.profit_rate.min &&
          _ctx.profitRateOption?.profit_rate.max &&
          _ctx.profitRateOption?.profit_rate.min !==
            _ctx.profitRateOption?.profit_rate.max
        )
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "~"))
            : _createCommentVNode("", true),
          (_ctx.profitRateOption?.profit_rate.max)
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass({
          'text-primary': Number(_ctx.profitRateOption?.profit_rate.max) >= 0,
          'text-danger': Number(_ctx.profitRateOption?.profit_rate.max) < 0,
        })
              }, _toDisplayString(Number(_ctx.profitRateOption?.profit_rate.max).toFixed(2)) + "% ", 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
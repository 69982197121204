/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-30 10:49:08
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-02-16 09:12:15
 */
export enum OrderRequestProgress {
  Draft = 1,
  New = 5,
  Requested = 10,
  Confirmed = 20,
  Rejected = 25,
  Shipped = 30,
  Canceled = 40,
  Completed = 50,
}

export enum OrderRequestType {
  MerchantOrder = 1,
  AgentPurchaseOrder,
  Draft,
  DoubleSaleOrder,
}

export enum invoiceStatus {
  Pending = 10,
  Invoiced = 20,
  Paid = 30,
  Unpaid = 40,
}

export enum OrderRequestPaymentType {
  PartnerContract = "Partner Contract",
  DirectPayment = "Direct Payment",
}

export enum OrderRequestPaymentMethod {
  PrivatePerson = "Private Person",
  TEDebitCardOne = "TE Debit Card 1: **** 647",
  TEDebitCardTwo = "TE Debit Card 2: **** 5036",
  TEMasterCard = "TE Master Card: **** 1705",
  TEPaypalAccount = "TE Paypal Account",
  TEBankTransfer = "TE Bank Transfer",
}

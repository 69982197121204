
import { computed, defineComponent, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import { ApiOrderAssign } from "@/core/api";
import _ from "lodash";
import {
  OrderAssginReturnTable,
  orderAssginTable,
  OrderAssginTable,
} from "@/core/directive/interface/orderAssign";
import { modalShowListener } from "@/core/directive/function/common";
import OrderTrackingIcon from "@/components/layout/OrderTrackingIcon.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";

export default defineComponent({
  components: { OrderTrackingIcon, SkuInfo, ProductInfo },
  name: "mb-order-routing-routing-overview-return",
  props: {
    tableItemData: {
      type: Object as () => OrderAssginTable,
      default: () => {
        return Object.assign({}, orderAssginTable);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const orderRoutingReturnOtherModalRef = ref<CommonHtmlType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      ids: [] as number[],
      products: [] as OrderAssginReturnTable[],
      items: [] as OrderAssginReturnTable[],
    });

    const isSelectable = (row, index) => {
      let flag = true;
      if (row.status === 1) {
        flag = false;
      }
      return flag;
    };

    const handleSelectionChange = (selection) => {
      if (selection.length === 0) {
        formData.value.ids = [];
        formData.value.products = [];
      } else {
        selection.map((item) => {
          const findIndex = formData.value.ids.indexOf(item.guid);
          if (findIndex === -1) {
            formData.value.ids.push(item.guid);
            formData.value.products.push(item);
          }
        });
      }
    };

    const handleSelectChange = (selection, row) => {
      const findIndex = formData.value.ids.indexOf(row.guid);
      if (findIndex !== -1) {
        formData.value.ids.splice(findIndex, 1);
        formData.value.products.splice(findIndex, 1);
      } else {
        formData.value.ids.push(row.guid);
        formData.value.products.push(row);
      }
    };

    const isDisabledSubmit = computed(() => {
      let isFlag = false;
      if (formData.value.ids.length === 0) {
        return true;
      }
      return isFlag;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          submitRequest();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitRequest = async () => {
      if (loading.value) return;
      loading.value = true;
      const { data } = await ApiOrderAssign.returnOrderAssignItem({
        item_id: props.tableItemData.id,
        identification_code: formData.value.ids,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          emit("update-list");
          hideModal(orderRoutingReturnOtherModalRef.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getShowInfo = async () => {
      const { data } = await ApiOrderAssign.getOrderAssignItemReturn({
        item_id: props.tableItemData.id,
      });
      if (data.code == 0) {
        formData.value.items = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value.ids = [];
      formData.value.products = [];
      formData.value.items = [];
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      });
    };

    onMounted(() => {
      modalShowListener(orderRoutingReturnOtherModalRef.value, () => {
        init();
      });
      modalHideListener(orderRoutingReturnOtherModalRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      orderRoutingReturnOtherModalRef,
      loading,
      formData,
      formRef,
      isDisabledSubmit,
      isSelectable,
      handleSelectChange,
      handleSelectionChange,
      submit,
      resetForm,
    };
  },
});

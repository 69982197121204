
import { ChannelOption } from "@/core/directive/interface/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-channel-option",
  props: {
    channelOption: {
      type: Object as () => ChannelOption,
    },
    discountValue: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {};
  },
});

/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-10-18 09:19:08
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-19 18:19:11
 */
import { NumberOrString } from "../type/common";
import { InventoryRuleKey, InventoryRuleDuration } from "../type/inventoryRule";
import { RelationSku, skuItemData } from "./order";
import { OrderRequestOverviewRelationItemRelationFulfillment } from "./orderRequest";
import {
  salesOrderOverviewFormData,
  SalesOrderOverviewSalesOrderExtendedPriorityManagement,
} from "./salesOrder";

export const orderAssginMerchantOrderFilterOption: OrderAssginMerchantOrderFilterOption =
  {
    merchant: "",
    channel: [],
  };

export const orderAssginTable: OrderAssginTable = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  order_id: 0,
  order_number: "",
  items_id: 0,
  product_id: 0,
  ordered_qty: 0,
  allow_cancel_qty: 0,
  assigned_qty: 0,
  canceled_fulfill_qty: 0,
  sku_id: "",
  store_id: 0,
  position: "",
  order_date: "",
  order_number_encry: "",
  flag: 0,
  brand_id: 0,
  brand_name: "",
  name_en: "",
  merchant_article_no: "",
  brand_article_no: "",
  merchant_channel_id: 0,
  warehouse_category: 0,
  base_discount_amount: "",
  base_price_incl_tax: "",
  base_row_tax_amount: "",
  base_row_total_incl_tax: "",
  base_unit_sales_order_price: "",
  base_unit_sales_order_net_price: "",
  relation_items_id: 0,
  base_original_price: "",
  due_date: "",
  open_qty: 0,
  min_qty: 0,
  __show: {
    status: "",
    definite_inventory: "",
    distribution_model: "",
  },
  pim_notice: "",
  fulfill_notice: "",
  definite_inventory: "",
  definite_inventory_source: "",
  purchase_required: "",
  distributor_order: "",
  old_order_number: "",
  relation_sales_order: {
    status: 0,
  },
  is_priority: 0,
  min_discount_requirement: "",
  min_discount_value_requirement: 0,
  range_size: "",
  relation_sku: skuItemData,
  relation_priority_management:
    salesOrderOverviewFormData.sales_order_extended_priority_management,
  downloadDisabled: false,
};

export interface OrderAssginTable {
  id: NumberOrString;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  order_id: NumberOrString;
  order_number: string;
  items_id: NumberOrString;
  product_id: NumberOrString;
  ordered_qty: number;
  allow_cancel_qty: number;
  assigned_qty: number;
  canceled_fulfill_qty: number;
  sku_id: string;
  store_id: number;
  position: string;
  order_date: string;
  order_number_encry: string;
  flag: number;
  brand_id: NumberOrString;
  brand_name: string;
  name_en: string;
  merchant_article_no: string;
  brand_article_no: string;
  merchant_channel_id: NumberOrString;
  warehouse_category: NumberOrString;
  base_discount_amount: string;
  base_price_incl_tax: string;
  base_row_tax_amount: string;
  base_row_total_incl_tax: string;
  base_unit_sales_order_price: string;
  base_unit_sales_order_net_price: string;
  relation_items_id: NumberOrString;
  base_original_price: string;
  due_date: string;
  open_qty: number;
  min_qty: number;
  __show: OrderAssginTableShow;
  pim_notice: string;
  fulfill_notice: string;
  definite_inventory: string;
  definite_inventory_source: string;
  purchase_required: string;
  distributor_order: string;
  old_order_number: string;
  relation_sales_order: OrderAssginTableRelationSalesOrder;
  is_priority: number;
  min_discount_requirement: string;
  min_discount_value_requirement: number;
  range_size: string;
  relation_sku: RelationSku;
  relation_priority_management: SalesOrderOverviewSalesOrderExtendedPriorityManagement;
  downloadDisabled: boolean;
}

interface OrderAssginTableShow {
  status: string;
  definite_inventory: string;
  distribution_model: string;
}

interface OrderAssginTableRelationSalesOrder {
  status: number;
}
// {
//   order_number: string;
//   position: string;
//   due_date: string;
//   ordered_qty: number;
//   open_qty: number;
//   min_qty: number;
//   relation_sku: RelationSku;
//   relation_sales_order: OrderAssginTableRelationSalesOrder;
//   downloadDisabled: boolean;
// }

interface OrderAssginTableRelationSalesOrder {
  status: number;
}

export interface OrderAssginMerchantOrderTable {
  channel_id: string;
  channel_name: string;
  channel_type: string;
  merchant_id: string;
  merchant_name: string;
  total_qty: number;
  rules: OrderAssginMerchantOrderTableRule[];
  items: OrderAssginMerchantOrderSubTable[];
  isChecked: boolean;
  isExpand: boolean;
  selectedSubtotal: number;
  selectedQty: number;
  fulfill_ids: Map<number[], OrderAssginMerchantOrderSubTable>;
  totalAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  brandAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  categoryAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  orderMinAmountErrorRule: OrderAssginMerchantOrderTableRule | null;
  brandMinAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  categoryMinAmountErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  pcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  brandPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  categoryPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  skuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  brandSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  categorySkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  orderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  brandOrderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  categoryOrderSkuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
}

export interface OrderAssginMerchantOrderTableRule {
  brand: string;
  category: string;
  duration: InventoryRuleDuration;
  key_type: InventoryRuleKey;
  value: string;
  skus: Map<string, number>;
  sku: string;
}

export interface OrderAssginMerchantOrderSubTable {
  article_name: string;
  article_no: string;
  brand: string;
  brand_name: string;
  category: string[];
  category_tree_path: string[];
  color: string;
  crowd: string;
  crowd_tree_path: string[];
  fulfill_ids: number[];
  original_price: string;
  product_gallery: string;
  product_name: string;
  qty: number;
  size: string;
  sku_id: string;
  relation_fulfillment: OrderRequestOverviewRelationItemRelationFulfillment[];
  max_qty: number;
  isChecked: boolean;
  amountErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  pcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  minAmountErrorRule: OrderAssginMerchantOrderTableRule | null;
  skuPcsErrorRule: Array<OrderAssginMerchantOrderTableRule>;
  orderSkuPcsErrorRule: OrderAssginMerchantOrderTableRule | null;
}

export interface OrderAssginMerchantOrderFilterOption {
  merchant: string;
  channel: string[];
}

export interface OrderAssginMerchantOrderFulfilledList {
  channel: string;
  fulfill_ids: number[];
  qty: number;
  sku_id: string;
}

export interface OrderAssginMerchantOrderRuleMap {
  total_qty: number;
  total_amount: NumberOrString;
  rules: OrderAssginMerchantOrderTableRule[];
  brands: Map<string, number>;
  subBrands: Map<string, number>;
  sub_total: number;
  sub_qty: number;
  items: Map<number[], OrderAssginMerchantOrderSubTable>;
}

export interface OrderAssginReturnTable {
  guid: string;
  status: number;
  stage: OrderAssginReturnTableStage;
  image: string;
  name: string;
  name_en: string;
  size: string;
  color: string;
  original_color: string;
  brand_article_no: string;
  merchant_article_no: string;
  brand: string;
}

export interface OrderAssginReturnTableStage {
  node: string;
  key: string;
}

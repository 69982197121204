/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-06 09:28:27
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-08 10:38:27
 */
export enum InventoryRuleDuration {
  Daily = 10,
  Monthly = 20,
  Weekly = 30,
  EveryThreeDays = 40,
  HalfYear = 50,
  Quarterly = 60,
}

export enum InventoryRuleKey {
  GrossAmount = 10,
  PCS = 15,
  Order = 11,
  OrderMinAmount = 21,
  PCSSKU = 25,
  PCSSKUOrder = 30,
}

export enum InventoryRuleLifecycleStatus {
  Launched = 10,
  Trial = 20,
}

export const InventoryRules = {
  channel_number: 1,
  sku_number: 1,
  data_list: {
    "4": {
      channel_id: 4,
      merchant_id: 2,
      channel_type: "Point Of Sale",
      channel_name: "A&G Königssee",
      total_qty: 3,
      items: {
        "240076725776551936": {
          sku_id: "240076725776551936",
          qty: 3,
          original_price: "1430.0000",
          fulfill_ids: [44611],
          article_no: "H20911A0010168950999",
          brand_name: "Moncler",
          brand: "1978",
          category: [],
          category_tree_path: ["1", "2", "32"],
          crowd: "126",
          crowd_tree_path: ["117"],
          article_name: "Corydale Short Down Jacket",
          size: "3",
          color: "black",
          product_name: "Corydale Short Down Jacket",
          product_gallery:
            "http://cdn-media.magic-bean-service.com/staging/media/2022/10/25/h20911a0010168950999_1_1_5_1.jpg",
        },
      },
      rules: [],
    },
    "1181": {
      channel_id: 1181,
      merchant_id: 525,
      channel_type: "Point Of Sale",
      channel_name: "August Pfüller Damen & Herrn",
      total_qty: 4,
      items: {
        "238546724862300160": {
          sku_id: "238546724862300160",
          qty: 4,
          original_price: "1000.0000",
          fulfill_ids: [44590, 44604],
          article_no: "\u200e699296 92TCG 8563",
          brand_name: "Gucci",
          brand: "1246",
          category: ["1", "18"],
          category_tree_path: ["1", "2", "18"],
          crowd: "126",
          crowd_tree_path: ["117"],
          article_name: "Horsebit 1955 Mini crossbody bag",
          size: "- / One size fits all",
          color: "multicolor",
          product_name: "Horsebit 1955 Mini crossbody bag",
          product_gallery:
            "http://cdn-media.magic-bean-service.com/staging/media/2022/10/21/p00746529_1_1.jpg",
        },
      },
      rules: [
        {
          brand: "",
          category: "1",
          key_type: 25,
          value: 2,
          skus: {
            "238546724862300160": 0,
          },
        },
        {
          brand: "",
          category: "1",
          key_type: 10,
          value: "3200.0000",
        },
        {
          brand: "",
          category: "18",
          key_type: 10,
          value: "1200.0000",
        },
        {
          brand: "1246",
          category: "18",
          key_type: 10,
          value: "2000.0000",
        },
      ],
    },
    "1182": {
      channel_id: 1182,
      merchant_id: 525,
      channel_type: "Point Of Sale",
      channel_name: "August Pfüller Kids",
      total_qty: 1,
      items: {
        "238546724862300160": {
          sku_id: "238546724862300160",
          qty: 1,
          original_price: "1000.0000",
          fulfill_ids: [44591],
          article_no: "\u200e699296 92TCG 8563",
          brand_name: "Gucci",
          brand: "1246",
          category: ["1", "18"],
          category_tree_path: ["1", "2", "18"],
          crowd: "126",
          crowd_tree_path: ["117"],
          article_name: "Horsebit 1955 Mini crossbody bag",
          size: "- / One size fits all",
          color: "multicolor",
          product_name: "Horsebit 1955 Mini crossbody bag",
          product_gallery:
            "http://cdn-media.magic-bean-service.com/staging/media/2022/10/21/p00746529_1_1.jpg",
        },
      },
      rules: [
        {
          brand: "",
          category: "1",
          key_type: 25,
          value: 2,
          skus: {
            "238546724862300160": 0,
          },
        },
        {
          brand: "",
          category: "1",
          key_type: 10,
          value: "5000.0000",
        },
        {
          brand: "",
          category: "18",
          key_type: 10,
          value: "3000.0000",
        },
        {
          brand: "1246",
          category: "18",
          key_type: 10,
          value: "2000.0000",
        },
      ],
    },
    "1265": {
      channel_id: 1265,
      merchant_id: 563,
      channel_type: "Online Store",
      channel_name: "24S.com",
      total_qty: 3,
      items: {
        "240076823428337664": {
          sku_id: "240076823428337664",
          qty: 3,
          original_price: "1230.0000",
          fulfill_ids: [44609],
          article_no: "H20911A0010168950999",
          brand_name: "Lemaire",
          brand: "1656",
          category: ["1"],
          category_tree_path: ["1", "2", "32"],
          crowd: "126",
          crowd_tree_path: ["117"],
          article_name: "Corydale Short Down Jacket",
          size: "1",
          color: "black",
          product_name: "Corydale Short Down Jacket",
          product_gallery:
            "http://cdn-media.magic-bean-service.com/staging/media/2022/10/25/h20911a0010168950999_1_1_5_1.jpg",
        },
      },
      rules: [
        {
          brand: "",
          category: "1",
          key_type: 25,
          value: 2,
          skus: {
            "240076823428337664": 0,
          },
        },
      ],
    },
    "1315": {
      channel_id: 1315,
      merchant_id: 588,
      channel_type: "Online Store",
      channel_name: "Acnestudios",
      total_qty: 3,
      items: {
        "238546724862300160": {
          sku_id: "238546724862300160",
          qty: 3,
          original_price: "1000.0000",
          fulfill_ids: [44583, 44593],
          article_no: "\u200e699296 92TCG 8563",
          brand_name: "Gucci",
          brand: "1246",
          category: [],
          category_tree_path: ["1", "2", "18"],
          crowd: "126",
          crowd_tree_path: ["117"],
          article_name: "Horsebit 1955 Mini crossbody bag",
          size: "- / One size fits all",
          color: "multicolor",
          product_name: "Horsebit 1955 Mini crossbody bag",
          product_gallery:
            "http://cdn-media.magic-bean-service.com/staging/media/2022/10/21/p00746529_1_1.jpg",
        },
      },
      rules: [],
    },
  },
  filter_options: {
    merchants: [
      {
        label: "August Pfüller GmbH & Co. KG",
        value: 525,
      },
    ],
    channels: {
      "525": [
        {
          label: "August Pfüller Damen & Herrn",
          value: 1181,
        },
        {
          label: "August Pfüller Kids",
          value: 1182,
        },
      ],
    },
  },
};


import { ProfitRateChannel } from "@/core/directive/interface/merchant";
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-profit-rate-channel-option",
  props: {
    profitRateChannelOption: {
      type: Object as () => ProfitRateChannel,
    },
    discountValue: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {};
  },
});

/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 15:27:36
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-10-17 16:57:30
 */
export enum FulfillmentOrderStatus {
  Initialized = 10,
  Assigning = 20,
  Assigned = 30,
  Approved = 50,
  Review = 100,
  Shipping = 150,
  Fulfilled = 200,
  Rejected = 240,
  Canceled = 250,
  OnHold = 300,
  Completed = 400,
}

export type FulfillmentOrderStatusAll = keyof typeof FulfillmentOrderStatus;

export enum FulfillmentSource {
  Merchant = 1,
  PreStock,
}

export type FulfillmentSourceAll = keyof typeof FulfillmentSource;

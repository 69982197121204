/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-01-27 11:35:43
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-01-19 20:06:54
 * @FilePath: /fulfillment-frontend/src/core/directive/function/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import store from "@/store";
import { User } from "@/store/modules/AuthModule";
import { CommonLanguageType } from "../type/common";
import { TaggingItem } from "../interface/common";
import { computed } from "vue";

export const getUserWarhouseName = (language: CommonLanguageType): string => {
  const userInfo: User = store.getters.currentUser;
  let warehouseName = language("inspection.frankfurtWarehouse");
  if (userInfo.wms_default_warehouse === "EPS-FRANKFURT") {
    warehouseName = language("inspection.epsWarehouse");
  }
  return warehouseName;
};

export const getUserWarhouseDescription = (
  language: CommonLanguageType
): string => {
  const userInfo: User = store.getters.currentUser;
  let warhouseDescription = language(
    "inspection.frankfurtWarehouseDescription"
  );
  if (userInfo.wms_default_warehouse === "EPS-FRANKFURT") {
    warhouseDescription = language("inspection.epsWarehouseDescription");
  }
  return warhouseDescription;
};

export const filterUserSourceData = (data: TaggingItem[]) => {
  const userInfo: User = store.getters.currentUser;
  if (userInfo.user_type === 1) {
    if (userInfo.user_managed_accounts.customer) {
      return data.filter((item) => {
        return userInfo.user_managed_accounts.customer.includes(
          Number(item.value)
        );
      });
    } else {
      return [];
    }
  }
  return data;
};

export const getOwnerLabel = computed(() => {
  return (value) => {
    let str = "";
    const ownerSwitch = store.getters.currentOwner;
    ownerSwitch.map((item) => {
      if (item.value === String(value)) {
        str = item.label;
      }
    });
    return str;
  };
});

export const isMB = computed(() => {
  let flag = false;
  const userInfo: User = store.getters.currentUser;
  if (userInfo.settlement_default_owner == "12495") {
    flag = true;
  }
  return flag;
});

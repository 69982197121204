
import { defineComponent, ref, onMounted, watch } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiFulfillmentOrders } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  CommonButtonType,
  CommonFormType,
  CommonHtmlType,
} from "@/core/directive/type/common";
// import { FormRulesMap } from "element-plus/es/components/form/src/form.type";

export default defineComponent({
  name: "cancel-fulfillment-order-modal",
  components: {},
  props: {
    id: { type: Number, required: true },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<CommonFormType>(null);
    const cancelFulfillmentOrder = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonButtonType>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: props.id,
      notice: "",
    });

    const options = ref({});

    const rules = ref({
      notice: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newValue) => {
        formData.value.id = newValue;
      }
    );

    const submit = () => {
      if (!loading.value) {
        if (!formRef.value) {
          return;
        }
        formRef.value.validate((valid: boolean) => {
          if (valid) {
            Swal.fire({
              text: t("fulfillmentOrder.voidTip"),
              icon: "warning",
              showCancelButton: true,
              buttonsStyling: false,
              confirmButtonText: t("common.yes"),
              cancelButtonText: t("common.no"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                if (submitButton.value) {
                  submitButton.value.setAttribute("data-kt-indicator", "on");
                }
                loading.value = true;
                const { data } =
                  await ApiFulfillmentOrders.cancelFulfillmentOrderData(
                    formData.value
                  );
                loading.value = false;
                submitButton.value?.removeAttribute("data-kt-indicator");
                if (data.code == 0) {
                  showFormSubmitSuccessMsg(() => {
                    resetForm();
                    hideModal(cancelFulfillmentOrder.value);
                    emit("update-list");
                  });
                } else {
                  showServerErrorMsg(data);
                }
              }
            });
          } else {
            showValidateErrorMsg();
            return false;
          }
        });
      }
    };

    const handleDiscard = () => {
      hideModal(cancelFulfillmentOrder.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const updateList = () => {
      resetForm();
      hideModal(cancelFulfillmentOrder.value);
      emit("update-list");
    };

    onMounted(() => {
      modalHideListener(cancelFulfillmentOrder.value, () => {
        resetForm();
      });
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      cancelFulfillmentOrder,
      submitButton,
      options,
      submit,
      handleDiscard,
      resetForm,
      updateList,
    };
  },
});

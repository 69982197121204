
import { ProfitRate } from "@/core/directive/interface/merchant";
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-profit-rate-option",
  props: {
    profitRateOption: {
      type: Object as () => ProfitRate,
    },
    discountValue: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {};
  },
});

/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 18:50:37
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-24 21:20:15
 */
import {
  NumberOrString,
  CommonIdType,
  CommonDateType,
  CommonDropdownSwtich,
  CommonRequiredSwtich,
  CommonSwtich,
} from "@/core/directive/type/common";
import { CurrencyType } from "../type/order";
import { OrderManagerPaymentMethod } from "../type/salesOrder";
import {
  AddressData,
  defaultAddressData,
  defaultProfileData,
  DoubleSaleAddressData,
  ProfileData,
} from "./common";
import { ProductItemImage } from "./order";

export const salesOrderOverviewFormData: SalesOrderOverview = {
  id: 0,
  // Sales Order
  sales_order: {
    brand: [],
    deposit_payment_status: 0,
    merchant_name: "",
    standard_delivery_date: "",
    encrypt_order_number: "",
    date_of_arrival: "",
    total_qty_ordered: 0,
    total_qty_confirmed: 0,
    store_id: 0,
    owner_id: 0,
    show_order_date: "",
    shipment: {
      shipment_id: 0,
      id: 0,
    },
  },
  sales_order_extended_distribution: {
    distribution_model: "",
    external_order_number: "",
    membership_group: "",
    overall_notice: "",
    rfm_group_trend: "",
    sales_manager: "",
    verified_status: 0,
    mb_segmentation: [],
    __show: {
      distribution_channel: "",
      distribution_model: "",
      rfm_group_trend: "",
      sales_manager: "",
    },
  },
  sales_order_extended_fulfillment: {
    mb_fulfillment_type: "",
    bc_custom_clearance: CommonSwtich.InActive,
    additional_fulfillment_service: CommonSwtich.InActive,
    required_documents: [],
    packaging_service: "",
    partial_shipment: "",
    complete_order_manually: 0,
    fulfillment_notice: "",
    verified_status: 0,
    tax_duties_terms: "",
    fulfillment_manager: "",
    __show: {
      tax_duties_terms: "",
      packaging_service: "",
      partial_shipment: "",
    },
  },
  sales_order_extended_pim: {
    pim_manager: "",
    inventory_confirmation_date: "",
    minimum_inventory_request: 0,
    mb_order_type: "",
    verified_status: 0,
    definite_inventory_source: "",
    __show: {
      pim_manager: "",
    },
  },
  sales_order_extended_priority_management: {
    priority_level: "",
    priority_handling: 0,
    delivery_date_diff: 0,
    prioritised_close_date: "",
    standard_delivery_date: "",
    verified_status: 0,
    high_priority_order: CommonSwtich.InActive,
    __show: {
      priority_level: "",
    },
  },
  sales_order_extended_settlement: {
    offline_payment_controlling: CommonRequiredSwtich.NotRequried,
    track_payment_status: "",
    apply_payment_deferral: "",
    credit_risk_control: "",
    offline_payment_due_date: "",
    additional_settlement_notice: "",
    bc_custom_clearance: CommonSwtich.InActive,
    offline_payment_controller: "",
    settlement_mode: "",
    deposit_in_advance: CommonSwtich.InActive,
    verified_status: 0,
  },

  // Sales Order identifier
  sales_order_identifier: {
    fulfillment_status: "",
    order_number: "",
    order_date: "",
    business_date: "",
    order_type: 0,
    order_status: "",
    sales_channel: "",
    settlement_status: 0,
    status: 0,
  },

  // Account Information
  sales_order_account_information: {
    user_account_name: "",
    user_mobile_country: "",
    user_mobile_number: "",
    user_email: "",
    customer_group: "",
    account_id: "",
  },

  // Billing Address
  billing_address: {
    billing_contact_email: "",
    firstname: "",
    lastname: "",
    cart_id: "",
    telephone_code: "",
    telephone: "",
    email: "",
    country_id: "",
    country: "",
    region_id: 0,
    region: "",
    city: "",
    district: "",
    street: "",
    postcode: "",
    company: "",
  },

  // Shipping Address
  shipping_address: {
    recipient_name: "",
    recipient_telephone_code: "",
    recipient_mobile_no: "",
    recipient_street_address: "",
    recipient_postal_code: "",
    recipient_city: "",
    recipient_province: "",
    recipient_country_id: "",
    recipient_country: "",
    destination_zone: "",
  },

  // Payment Information
  payment_information: {
    order_state: "",
    payment_method: "",
    payment_provider: "",
    currency: "",
    payment_status: "",
    paid_amount: "",
  },

  // Shipping & Handling Information
  shipping_handling_information: {
    shipping_method: "",
    shipping_fee_mode: "",
  },

  // Order Totals
  order_totals: {
    sub_total: "",
    base_sub_total: "",
    sub_total_refunded: "",
    base_sub_total_refunded: "",
    total_paid: "",
    base_total_due: "",
    base_refunded_amount: "",
    base_total_paid: "",
    tax_amount: "",
    base_tax_amount: "",
    discount_amount: "",
    base_discount_amount: "",
    shipping_amount: "",
    base_shipping_amount: "",
    grand_total_net: "",
    base_grand_total_net: "",
    grand_total_gross: "",
    base_grand_total_gross: "",
    cost: "",
    base_cost: "",
    total_refunded: "",
    base_total_refunded: "",
  },

  // Addtional Specification
  additional_specification: {
    campaign: {
      discount_label: "",
      discount_amount: "",
    },
    internal_comment: "",
    comment: [],
  },
  ordered_items: [],
  __show: {
    settlement_status: "",
    status: "",
  },
  order_currency_code: "",
  base_currency_code: "",
};

export const salesOrderOverviewInvoiceData: SalesOrderOverviewInvoice = {
  invoices: [],
  list: [],
};

export const salesOrderProfillingFormData: SalesOrderProfilling = {
  distribution: {
    sales_manager: "",
    order_manager: "",
    external_order_number: "",
    mb_order_type: "",
    traffic_source: "",
    traffic_source_identifier: "",
    influence_order_type: "",
    mb_segmentation: [],
    distribution_channel: "",
    distribution_model: "",
    membership_group: "",
    influence_account_id: "",
    overall_notice: "",
    verified_status: 0,
    verified_notice: "",
  },
  priority_management: {
    high_priority_order: CommonSwtich.InActive,
    priority_handling: "",
    priority_level: "",
    prioritised_close_date: "",
    priority_notice: "",
    exception_risk_level: "",
    verified_status: 0,
    verified_notice: "",
  },
  pim: {
    pim_manager: "",
    additional_pim_service: CommonSwtich.InActive,
    definite_inventory_source: "",
    inventory_notice: "",
    minimum_inventory_request: 0,
    inventory_confirmation_date: "",
    additional_pim_notice: "",
    package_requirement: "",
    mb_order_type: "1",
    verified_status: 0,
    verified_notice: "",
    __show: {
      pim_manager: "",
    },
  },
  fulfillment: {
    fulfillment_manager: "",
    order_id: 0,
    mb_fulfillment_type: "",
    additional_fulfillment_service: CommonSwtich.InActive,
    required_documents: [],
    additional_notification: [],
    packaging_service: "",
    photographing_service: [],
    packaging_requirement: "",
    partial_shipment: "",
    qa_report: "",
    fulfillment_notice: "",
    exception_risk_level: "",
    complete_order_manually: CommonDropdownSwtich.No,
    partial_whole_shipment: "",
    tax_duties_terms: "",
    verified_status: 0,
    verified_notice: "",
  },
  quotation_information: {
    file_id: 0,
    file_name: "",
    time: "",
  },
  settlement_bc_payment: {
    bc_custom_clearance: CommonSwtich.InActive,
    payer_identifier: "",
    payer: "",
    payer_identity_card_no: "",
    payer_mobile_phone: "",
    mb_annual_limit_verification: "",
    custom_limit_verification: CommonSwtich.InActive,
    verified_status: 0,
    verified_notice: "",
  },
  settlement_fraud_controlling: {
    settlement_type: "",
    billing_shipping_zone_conflict: 0,
    apply_payment_deferral: CommonSwtich.InActive,
    credit_risk_control: "",
    offline_payment_due_date: "",
    additional_settlement_notice: "",
    approve_order_processing_date: "",
    approve_order_processing_user: 0,
    approve_overseas_shipment_date: "",
    approve_overseas_shipment_user: 0,
    approve_final_shipment_date: "",
    approve_final_shipment_user: 0,
    settlement_mode: "",
    deposit_in_advance: CommonSwtich.InActive,
    settlement_in_advance: CommonDropdownSwtich.No,
    offline_payment_controller: "",
    offline_payment_controlling: CommonRequiredSwtich.NotRequried,
    track_payment_status: CommonSwtich.InActive,
    verified_status: 0,
    verified_notice: "",
  },
  settlement_tax_refund: {
    additional_tax_refund_service: CommonSwtich.InActive,
    settlement_in_advance: CommonDropdownSwtich.No,
    custom_settlement_date: "",
    business_date: "",
    last_billing_date: "",
    has_billing: false,
    tax_refund_number: "",
    verified_status: 0,
    verified_notice: "",
  },
  deposit_currency: "",
  deposit_amount: 0,
  payment_voucher: [],
  created_at: "",
  verified_date: "",
};

export const draftOrderOverview: DraftOrderOverview = {
  id: "",
  config_id: 0,
  company_id: 0,
  can_generate_order: 0,
  website_id: 0,
  config_name: "",
  created_at: "",
  order_number: "",
  old_order_number: "",
  is_real_order: 0,
  last_billing_date: "",
  merchant: "",
  channel: "",
  status: 0,
  settlement_status: 0,
  order_tagging: "",
  order_currency_code: "EUR",
  order_rate: "0.0",
  order_date: "",
  order_status: "",
  order_type: 0,
  owner_id: 0,
  customer_id: "",
  store_id: "",
  user_account_name: "",
  mobile: "",
  grand_total_base: 0,
  ordered_qty: 0,
  release_qty: 0,
  customer_firstname: "",
  customer_lastname: "",
  verified_date: "",
  billing_address: Object.assign({}, defaultAddressData),
  business_date: "",
  shipping_address: Object.assign({}, defaultAddressData),
  distributor_order: "",
  deposit_currency: "EUR",
  deposit_amount: 0,
  base_currency_code: "EUR",
  order_currency: "EUR",
  total_item_count: 0,
  base_total_paid: 0,
  payment_method: OrderManagerPaymentMethod.WeChatPay,
  deposit_payment_status: "",
  draft_items: [],
  relation_item_draft: [],
  base_shipping_amount: "0.00",
  // 前端定义字段用于页面逻辑
  isSameBillingAddress: false,
  billingAddress: "",
  billing_address_id: "",
  shippingAddress: "",
  shipping_address_id: "",
  sales_price_rule: null,
  purchase_price_rule: null,
  profile: Object.assign({}, defaultProfileData),
};

export const relationItemDraft: DraftOrderOverviewRelationItemDraft = {
  item_id: 0,
  entity_id: 0,
  product_id: 0,
  sku: "",
  assign_ocps_sku_no: "",
  type_id: "",
  brand_article_no: "",
  merchant_article_no: "",
  brand_id: 0,
  brand: "",
  brand_name: "",
  website_id: 0,
  name: "",
  name_en: "",
  color_id: 0,
  color_name: "",
  color_label: "",
  size_id: "",
  size_name: "",
  size_label: "",
  range_size: "",
  merchant_channel_id: 0,
  price: "",
  base_price: 0.01,
  // base_gross_price: 0.01,
  net_price: 0,
  sales_price: 0,
  discount: 100,
  discount_type: CurrencyType.Percent,
  tax_rate: "",
  qty: 1,
  remark: "",
  merchant_channel_name: "",
  images: [],
  image_id: 0,
  source_link: "",
  merchant_name: [],
};

export interface SalesOrderTable {
  order_number: string;
  order_date: CommonDateType;
  business_date: CommonDateType;
  order_type: number;
  order_status: string;
  ordered_qty: string;
  ship_to_country: string;
  fulfillment_status: string;
  sales_channel: string;
  bill_to: string;
  ship_to: string;
  grand_total_base: string;
  grand_total_purchase: string;
}

export interface SalesOrderOverview {
  id: CommonIdType;
  sales_order: SalesOrderOverviewSalesOrder;
  sales_order_identifier: SalesOrderOverviewIdentifier;
  sales_order_account_information: SalesOrderOverviewAccountInformation;
  sales_order_extended_distribution: SalesOrderOverviewSalesOrderExtendedDistribution;
  sales_order_extended_fulfillment: SalesOrderOverviewSalesOrderExtendedFulfillment;
  sales_order_extended_pim: SalesOrderOverviewSalesOrderExtendedPim;
  sales_order_extended_priority_management: SalesOrderOverviewSalesOrderExtendedPriorityManagement;
  sales_order_extended_settlement: SalesOrderOverviewSalesOrderExtendedSettlement;
  billing_address: SalesOrderOverviewBillingAddress;
  shipping_address: SalesOrderOverviewShippingAddress;
  payment_information: SalesOrderOverviewPaymentInformation;
  shipping_handling_information: SalesOrderOverviewShippingHandlingInformation;
  order_totals: SalesOrderOverviewOrderTotals;
  additional_specification: SalesOrderOverviewAddtionalSpecification;
  ordered_items: any[];
  __show: SalesOrderOverviewShow;
  order_currency_code: string;
  base_currency_code: string;
}

interface SalesOrderOverviewSalesOrder {
  brand: string[];
  deposit_payment_status: number;
  merchant_name: string;
  total_qty_ordered: number;
  total_qty_confirmed: number;
  standard_delivery_date: string;
  encrypt_order_number: string;
  date_of_arrival: string;
  store_id: number;
  owner_id: number;
  show_order_date: NumberOrString;
  shipment: SalesOrderOverviewSalesOrderShipment;
}

interface SalesOrderOverviewSalesOrderShipment {
  shipment_id: number;
  id: number;
}

interface SalesOrderOverviewSalesOrderAddress {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: number;
  updated_uid: number;
  deleted_uid: number;
  order_id: number;
  order_number: string;
  address_type: string;
  firstname: string;
  lastname: string;
  idcard: string;
  email: string;
  telephone: string;
  telephone_code: string;
  country_iso_2: string;
  region: string;
  city: string;
  district: string;
  street: string;
  zip_code: string;
}

interface SalesOrderOverviewIdentifier {
  fulfillment_status: string;
  order_number: string;
  order_date: string;
  order_type: NumberOrString;
  business_date: NumberOrString;
  order_status: string;
  sales_channel: string;
  settlement_status: number;
  status: number;
}

interface SalesOrderOverviewShow {
  settlement_status: string;
  status: string;
}

interface SalesOrderOverviewAccountInformation {
  user_account_name: string;
  user_mobile_country: string;
  user_mobile_number: string;
  user_email: string;
  customer_group: string;
  account_id: NumberOrString;
}

interface SalesOrderOverviewSalesOrderExtendedDistribution {
  external_order_number: string;
  membership_group: string;
  overall_notice: string;
  rfm_group_trend: string;
  sales_manager: string;
  verified_status: number;
  distribution_model: string;
  mb_segmentation: string[];
  __show: SalesOrderOverviewSalesOrderExtendedDistributionShow;
}

interface SalesOrderOverviewSalesOrderExtendedDistributionShow {
  distribution_channel: string;
  distribution_model: string;
  rfm_group_trend: string;
  sales_manager: string;
}

interface SalesOrderOverviewSalesOrderExtendedFulfillment {
  mb_fulfillment_type: string;
  additional_fulfillment_service: CommonSwtich;
  bc_custom_clearance: CommonSwtich;
  required_documents: string[];
  packaging_service: string;
  partial_shipment: string;
  complete_order_manually: number;
  fulfillment_notice: string;
  tax_duties_terms: string;
  fulfillment_manager: string;
  verified_status: number;
  __show: SalesOrderOverviewSalesOrderExtendedFulfillmentShow;
}

interface SalesOrderOverviewSalesOrderExtendedFulfillmentShow {
  packaging_service: string;
  tax_duties_terms: string;
  partial_shipment: string;
}

interface SalesOrderOverviewSalesOrderExtendedPim {
  pim_manager: string;
  inventory_confirmation_date: string;
  minimum_inventory_request: number;
  mb_order_type: string;
  verified_status: number;
  definite_inventory_source: string;
  __show: SalesOrderOverviewSalesOrderExtendedPimShow;
}

interface SalesOrderOverviewSalesOrderExtendedPimShow {
  pim_manager: string;
}

export interface SalesOrderOverviewSalesOrderExtendedPriorityManagement {
  priority_level: string;
  priority_handling: number;
  delivery_date_diff: number;
  prioritised_close_date: string;
  standard_delivery_date: string;
  verified_status: number;
  high_priority_order: CommonSwtich;
  __show: SalesOrderOverviewSalesOrderExtendedPriorityManagementShow;
}

interface SalesOrderOverviewSalesOrderExtendedPriorityManagementShow {
  priority_level: string;
}

interface SalesOrderOverviewSalesOrderExtendedSettlement {
  offline_payment_controlling: CommonRequiredSwtich;
  track_payment_status: string;
  apply_payment_deferral: string;
  credit_risk_control: string;
  offline_payment_due_date: string;
  additional_settlement_notice: string;
  bc_custom_clearance: CommonSwtich;
  settlement_mode: string;
  deposit_in_advance: CommonSwtich;
  offline_payment_controller: string;
  verified_status: number;
}

interface SalesOrderOverviewBillingAddress {
  billing_contact_email: string;
  firstname: string;
  lastname: string;
  cart_id: string;
  telephone_code: string;
  telephone: string;
  email: string;
  country_id: string;
  country: string;
  region_id: number;
  region: string;
  city: string;
  district: string;
  street: string;
  postcode: string;
  company: string;
}

interface SalesOrderOverviewShippingAddress {
  recipient_name: string;
  recipient_telephone_code: string;
  recipient_mobile_no: string;
  recipient_street_address: string;
  recipient_postal_code: string;
  recipient_city: string;
  recipient_province: string;
  recipient_country_id: string;
  recipient_country: string;
  destination_zone: string;
}

interface SalesOrderOverviewPaymentInformation {
  order_state: string;
  payment_method: string;
  payment_provider: string;
  currency: string;
  payment_status: string;
  paid_amount: string;
}

interface SalesOrderOverviewShippingHandlingInformation {
  shipping_method: string;
  shipping_fee_mode: string;
}

interface SalesOrderOverviewOrderTotals {
  sub_total: string;
  base_sub_total: string;
  sub_total_refunded: string;
  base_sub_total_refunded: string;
  total_paid: string;
  base_total_paid: string;
  tax_amount: string;
  base_tax_amount: string;
  discount_amount: string;
  base_discount_amount: string;
  shipping_amount: string;
  base_shipping_amount: string;
  grand_total_net: string;
  base_grand_total_net: string;
  grand_total_gross: string;
  base_grand_total_gross: string;
  base_total_due: string;
  base_refunded_amount: string;
  cost: string;
  base_cost: string;
  total_refunded: string;
  base_total_refunded: string;
}

interface SalesOrderOverviewAddtionalSpecification {
  campaign: SalesOrderOverviewAddtionalSpecificationCampaign;
  internal_comment: string;
  comment: Array<string>;
}

interface SalesOrderOverviewAddtionalSpecificationCampaign {
  discount_label: string;
  discount_amount: string;
}

export interface SalesOrderProfilling {
  distribution: SalesOrderProfillingDistribution;
  priority_management: SalesOrderProfillingPriorityManagement;
  pim: SalesOrderProfillingPim;
  fulfillment: SalesOrderProfillingFulfillment;
  quotation_information: SalesOrderProfillingQuotationInformation;
  deposit_amount: number;
  deposit_currency: string;
  payment_voucher: SalesOrderProfillingPaymentVoucher[];
  settlement_bc_payment: SalesOrderProfillingSettlementBcPayment;
  settlement_fraud_controlling: SalesOrderProfillingSettlementFraudControlling;
  settlement_tax_refund: SalesOrderProfillingSettlementTaxRefund;
  created_at: string;
  verified_date: string;
}

export interface SalesOrderProfillingDistribution {
  sales_manager: string;
  order_manager: string;
  external_order_number: string;
  mb_order_type: string;
  traffic_source: string;
  traffic_source_identifier: string;
  influence_order_type: string;
  mb_segmentation: string[];
  distribution_channel: string;
  distribution_model: string;
  membership_group: string;
  influence_account_id: string;
  overall_notice: string;
  verified_status: number;
  verified_notice: string;
}

export interface SalesOrderProfillingPriorityManagement {
  high_priority_order: CommonSwtich;
  priority_handling: string;
  priority_level: string;
  prioritised_close_date: string;
  priority_notice: string;
  exception_risk_level: string;
  verified_status: number;
  verified_notice: string;
}

export interface SalesOrderProfillingPim {
  pim_manager: string;
  additional_pim_service: NumberOrString;
  definite_inventory_source: string;
  inventory_notice: string;
  minimum_inventory_request: number;
  inventory_confirmation_date: string;
  additional_pim_notice: string;
  package_requirement: string;
  mb_order_type: string;
  verified_status: number;
  verified_notice: string;
  __show: SalesOrderProfillingPimShow;
}

export interface SalesOrderProfillingPimShow {
  pim_manager: string;
}

export interface SalesOrderProfillingFulfillment {
  order_id: NumberOrString;
  fulfillment_manager: string;
  mb_fulfillment_type: string;
  additional_fulfillment_service: number;
  required_documents: string[];
  additional_notification: string[];
  packaging_service: string;
  photographing_service: string[];
  packaging_requirement: string;
  partial_shipment: NumberOrString;
  qa_report: string;
  fulfillment_notice: string;
  exception_risk_level: string;
  complete_order_manually: number;
  partial_whole_shipment: string;
  tax_duties_terms: string;
  verified_status: number;
  verified_notice: string;
}

interface SalesOrderProfillingSettlementType {
  type: string;
}

interface SalesOrderProfillingQuotationInformation {
  file_id: number;
  file_name: string;
  time: string;
}

interface SalesOrderProfillingPaymentVoucher {
  file_id: number;
  file_name: string;
  time: string;
  url: string;
}

interface SalesOrderProfillingSettlementBcPayment {
  bc_custom_clearance: CommonSwtich;
  payer_identifier: string;
  payer: string;
  payer_identity_card_no: string;
  payer_mobile_phone: string;
  mb_annual_limit_verification: string;
  custom_limit_verification: CommonSwtich;
  verified_status: number;
  verified_notice: string;
}

export interface SalesOrderProfillingSettlementFraudControlling {
  settlement_type: string;
  billing_shipping_zone_conflict: number;
  apply_payment_deferral: CommonSwtich;
  credit_risk_control: string;
  offline_payment_due_date: string;
  additional_settlement_notice: string;
  approve_order_processing_date: string;
  approve_order_processing_user: number;
  approve_overseas_shipment_date: string;
  approve_overseas_shipment_user: number;
  approve_final_shipment_date: string;
  approve_final_shipment_user: number;
  settlement_in_advance: CommonDropdownSwtich;
  settlement_mode: string;
  deposit_in_advance: CommonSwtich;
  offline_payment_controller: string;
  offline_payment_controlling: CommonRequiredSwtich;
  track_payment_status: CommonSwtich;
  verified_status: number;
  verified_notice: string;
}

interface SalesOrderProfillingSettlementTaxRefund {
  additional_tax_refund_service: CommonSwtich;
  settlement_in_advance: CommonDropdownSwtich;
  tax_refund_number: string;
  custom_settlement_date: string;
  business_date: string;
  last_billing_date: string;
  has_billing: boolean;
  verified_status: number;
  verified_notice: string;
}

export interface SalesOrderOverviewInvoice {
  invoices: SalesOrderOverviewInvoiceInvoices[];
  list: SalesOrderOverviewInvoiceList[];
}

export interface SalesOrderOverviewInvoiceInvoices {
  total_net_amount: string;
  precent: SalesOrderOverviewInvoicePrecent;
  total_gross_amount: string;
  unit_gross_amount_total: string;
  shipping_fee: number;
}

interface SalesOrderOverviewInvoicePrecent {
  [key: string]: number;
}

export interface SalesOrderOverviewInvoiceList {
  product_number: string;
  brand: string;
  gallery: ProductItemImage[];
  product_name: string;
  original_color: string;
  size: string;
  unit_net_amount: string;
  tax_percent: string;
  net_total: string;
  qty: number;
}

export interface DraftOrderOverview {
  id: NumberOrString;
  company_id: NumberOrString;
  config_id: NumberOrString;
  can_generate_order: number;
  website_id: NumberOrString;
  config_name: string;
  created_at: string;
  order_number: string;
  old_order_number: string;
  is_real_order: number;
  last_billing_date: string;
  merchant: string;
  channel: string;
  status: number;
  settlement_status: number;
  order_currency_code: string;
  order_tagging: string;
  order_rate: string;
  order_date: string;
  order_status: string;
  order_type: number;
  owner_id: number;
  customer_id: NumberOrString;
  store_id: NumberOrString;
  user_account_name: string;
  mobile: string;
  grand_total_base: number;
  ordered_qty: number;
  release_qty: number;
  customer_firstname: string;
  customer_lastname: string;
  verified_date: string;
  billing_address: AddressData | DoubleSaleAddressData;
  business_date: string;
  shipping_address: AddressData;
  distributor_order: string;
  deposit_currency: string;
  deposit_amount: number;
  base_currency_code: string;
  order_currency: string;
  total_item_count: number;
  base_total_paid: number;
  payment_method: string;
  deposit_payment_status: string;
  draft_items: DoubleSaleDraftOrderOverviewRelationItemDraft[];
  relation_item_draft: DraftOrderOverviewRelationItemDraft[];
  base_shipping_amount: string;
  // 前端定义字段用于页面逻辑
  isSameBillingAddress: boolean;
  billingAddress: string;
  billing_address_id: string;
  shippingAddress: string;
  shipping_address_id: string;
  profile: ProfileData;
  sales_price_rule: DoubleSaleDraftOrderOverviewPriceRule | null;
  purchase_price_rule: DoubleSaleDraftOrderOverviewPriceRule | null;
}

export interface DoubleSaleDraftOrderOverviewPriceRule {
  fee: number[];
  vat_rate: number;
  calc_mode: string;
}

export interface DoubleSaleDraftOrderOverviewRelationItemDraft
  extends DraftOrderOverviewRelationItemDraft {
  position: string;
  in_blacklist: boolean;
  is_new: boolean;
  is_deleted: boolean;
  ean_code: string;
  country_of_origin: string;
  hs_code: string;
  material: string;
  deleted_at: string | null;
}

export interface DraftOrderOverviewRelationItemDraft {
  item_id: NumberOrString;
  entity_id: NumberOrString;
  product_id: NumberOrString;
  sku: string;
  assign_ocps_sku_no: string;
  type_id: string;
  brand_article_no: string;
  merchant_article_no: string;
  brand_id: NumberOrString;
  brand: string;
  brand_name: string;
  website_id: NumberOrString;
  name: string;
  name_en: string;
  color_id: NumberOrString;
  color_name: string;
  color_label: string;
  size_id: NumberOrString;
  size_name: string;
  size_label: string;
  range_size: string;
  merchant_channel_id: NumberOrString;
  price: string;
  base_price: number;
  // base_gross_price: number;
  net_price: number;
  sales_price: number;
  discount: number;
  discount_type: CurrencyType;
  tax_rate: NumberOrString;
  qty: number;
  remark: string;
  merchant_channel_name: string;
  images: string[];
  image_id: NumberOrString;
  source_link: string;
  merchant_name: string[];
  currency_sales_price?: number;
}


import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  computed,
  getCurrentInstance,
  onUpdated,
} from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdown from "@/views/mb-order-routing/routing-overview/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiFulfillmentOrders, ApiOrderAssign } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  MerchantOption,
  SortOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { CommonSwtich } from "@/core/directive/type/common";
import {
  getSalesOrderPriorityLevelMap,
  getSalesOrderPriorityLevelSvgStyleMap,
  getSalesOrderStatusMap,
} from "@/core/directive/function/salesOrder";
import store from "@/store";
import OrderAssignItem from "@/components/table/OrderAssignItem.vue";
import QuickRouting from "@/views/mb-order-routing/routing-overview/QuickRouting.vue";
import Refuse from "@/views/mb-order-routing/routing-overview/Refuse.vue";
import CancelFulfillmentOrderModal from "@/views/mb-order-routing/fulfillment-orders/CancelFulfillmentOrderModal.vue";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  getLateDays,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  orderAssginTable,
  OrderAssginTable,
} from "@/core/directive/interface/orderAssign";
import { useRoute, useRouter } from "vue-router";
import MerchantOrderModal from "@/views/mb-order-routing/routing-overview/MerchantOrderModal.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { SalesOrderPriorityLevel } from "@/core/directive/type/salesOrder";
import { Mutations } from "@/store/enums/StoreEnums";
import { checkPermissionFlag, checkUserFlag } from "@/directives";
import PermissionCommon from "../layout/PermissionCommon.vue";
import ReturnRefundModal from "@/views/mb-order-routing/routing-overview/ReturnRefundModal.vue";
import RefundOtherModal from "@/views/mb-order-routing/routing-overview/RefundOtherModal.vue";
import CancelOpenQty from "@/views/mb-order-routing/routing-overview/CancelOpenQty.vue";
import WindowResize from "../layout/WindowResize.vue";
import { isMB } from "@/core/directive/function/user";

export default defineComponent({
  name: "mb-order-routing-routing-overview-page",
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order 1: double sale
    },
    store_id: {
      type: String,
      default: "",
    },
  },
  components: {
    MBDatatablePlus,
    OrderAssignItem,
    FilterDropdown,
    QuickRouting,
    Refuse,
    CancelFulfillmentOrderModal,
    MerchantOrderModal,
    ProductInfo,
    SkuInfo,
    PermissionCommon,
    ReturnRefundModal,
    RefundOtherModal,
    CancelOpenQty,
    WindowResize,
  },
  setup(props) {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();
    const router = useRouter();
    const currentInstance: any = getCurrentInstance();

    const loading = ref(true);
    const tableData = ref<Array<OrderAssginTable>>([]);
    const tableItemData = ref<OrderAssginTable>(
      Object.assign({}, orderAssginTable)
    );
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const disabledExport = ref<boolean>(false);
    const disabledmerchantRequest = ref<boolean>(false);
    const disabledExportUnderstockEqualization = ref<boolean>(false);
    const disabledExportFulfillmentRejectedItems = ref<boolean>(false);

    const options = ref({
      CommonSwtich: CommonSwtich,
      assignable: CommonSwtich.Active,
      assignableOptions: [
        {
          label: t("orderAssign.all"),
          value: CommonSwtich.InActive,
        },
        {
          label: t("orderAssign.toBeAssigned"),
          value: CommonSwtich.Active,
        },
      ] as TaggingItem[],
      status: [] as TaggingItem[],
      fulfillment_status: [] as TaggingItem[],
      merchant: [] as MerchantOption[],
      unassigned_total: 0,
    });

    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "inventory_confirm_date_start",
        value: commonChangeFilterAddDate(moment(), -1, "months"),
        condition: "gteq",
      },
      {
        field: "inventory_confirm_date_end",
        value: commonChangeFilterAddDate(moment(), 15, "days"),
        condition: "lteq",
      },
    ]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "order_number", direction: "desc" },
      { field: "position", direction: "asc" },
    ]);

    const sortDefaultOptions = ref<Array<SortOption>>([
      { field: "order_number", direction: "desc" },
      { field: "position", direction: "asc" },
    ]);

    const itemId = ref(0);
    const itemPosition = ref("");
    const subId = ref(0);

    const tableHeader = ref([
      {
        name: t("order.orderNo"),
        key: "order_number",
        sortable: true,
      },
      {
        name: t("orderAssign.dueDate"),
        key: "due_date",
        sortable: true,
      },
      {
        name: t("salesOrder.priority"),
        key: "priority_level",
        sortable: true,
      },
      {
        name: t("order.article"),
        key: "article",
        sortable: false,
      },
      {
        name: t("order.sku"),
        key: "sku",
        sortable: false,
      },
      {
        name: t("order.qty"),
        key: "qty",
        sortable: false,
        align: "right",
      },
      {
        name: t("orderAssign.qtyCtrl"),
        key: "qty_ctrl",
        sortable: false,
        align: "right",
      },
      {
        name: t("salesOrder.status"),
        key: "order_status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const getStoreFilter = computed(() => {
      let arr: FilterOption[] = [];
      if (props.store_id) {
        arr.push({
          field: "store_id",
          value: props.store_id.split(","),
          condition: "in",
        });
      }
      return arr;
    });

    const doubleSaleTableHeader = computed(() => {
      if (props.store_id) {
        const arr: any[] = tableHeader.value.filter(
          (item) =>
            item.key !== "due_date" &&
            ((isMB.value && item.key !== "actions") || !isMB.value)
        );
        return arr;
      } else {
        return tableHeader.value;
      }
    });

    const doubleSaleFilter = computed(() => {
      if (props.store_id) {
        const arr: FilterOption[] = filterOptions.value.filter(
          (item) =>
            item.field !== "inventory_confirm_date_start" &&
            item.field !== "inventory_confirm_date_end"
        );
        return arr;
      } else {
        return filterOptions.value;
      }
    });

    const init = () => {
      setModuleBCN(t, route);
      Promise.all([updateList(), getDropdownOptions()])
        .then(() => {
          // MenuComponent.reinitialization();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    const filesExport = () => {
      if (disabledExport.value) true;
      disabledExport.value = true;
      ApiOrderAssign.export({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [...doubleSaleFilter.value, ...getStoreFilter.value],
        sort_orders: sortOptions.value,
        merchant_request: 1,
        assignable: options.value.assignable,
      })
        .then((data) => {
          disabledExport.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const fileExportUnderstockEqualization = () => {
      if (disabledExportUnderstockEqualization.value) true;
      disabledExportUnderstockEqualization.value = true;
      ApiFulfillmentOrders.exportUnderstockEqualization({})
        .then((data) => {
          disabledExportUnderstockEqualization.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportUnderstockEqualization.value = false;
          console.log(error);
        });
    };

    const fileExportFulfillmentRejectedItems = () => {
      if (disabledExportFulfillmentRejectedItems.value) true;
      disabledExportFulfillmentRejectedItems.value = true;
      ApiOrderAssign.exportFulfillmentRejectedItems({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [...doubleSaleFilter.value, ...getStoreFilter.value],
        sort_orders: sortOptions.value,
        merchant_request: 1,
        assignable: options.value.assignable,
      })
        .then((data) => {
          disabledExportFulfillmentRejectedItems.value = false;
          commonExportFile(data, "Fulfillment Order Report（rejected）.xlsx");
        })
        .catch((error) => {
          disabledExportFulfillmentRejectedItems.value = false;
          console.log(error);
        });
    };

    // disabledExportFulfillmentRejectedItems

    const fileExport = (order_number, item) => {
      if (item.downloadDisabled) return;
      item.downloadDisabled = true;
      ApiFulfillmentOrders.exportPriorityEqualization({
        order_number: order_number,
      })
        .then((data) => {
          item.downloadDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          item.downloadDisabled = false;
          console.log(error);
        });
    };

    const merchantRequest = async () => {
      if (disabledmerchantRequest.value) return;
      Swal.fire({
        text: t("fulfillmentOrder.merchantRequestTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          disabledmerchantRequest.value = true;
          const { data } = await ApiOrderAssign.getOrderAssignList({
            page: currentPage.value,
            page_size: pageSize.value,
            search_value: search.value,
            filter_group: [...doubleSaleFilter.value, ...getStoreFilter.value],
            sort_orders: sortOptions.value,
            merchant_request: 1,
            assignable: options.value.assignable,
          });
          disabledmerchantRequest.value = false;
          if (data.code == 0) {
            updateList();
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const getOrderAssignList = async () => {
      loading.value = true;
      const { data } = await ApiOrderAssign.getOrderAssignList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [...doubleSaleFilter.value, ...getStoreFilter.value],
        sort_orders: sortOptions.value,
        assignable: options.value.assignable,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        tableData.value.forEach((item) => {
          item.downloadDisabled = false;
        });
        total.value = data.data.total;
        options.value.unassigned_total = data.data.unassigned_total;
      }
    };

    const updateList = () => {
      getOrderAssignList();
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      const arr = sortOptions.value.filter(
        (item) => item.field !== val.columnName
      );
      arr.unshift(sortObj);
      sortOptions.value.splice(0, sortOptions.value.length, ...arr);
      updateList();
    };

    const assignableChange = () => {
      searchItems();
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (
            item == "status" ||
            item == "fulfillment_status" ||
            item == "channel" ||
            item == "definite_inventory_source" ||
            item == "order_tagging" ||
            item == "order_type"
          ) {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else if (item == "order_date_start") {
            filterArr.push({
              field: "order_date",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "order_date_end") {
            filterArr.push({
              field: "order_date",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "business_date_start") {
            filterArr.push({
              field: "business_date",
              value: val[item],
              condition: "gteq",
            });
          } else if (item == "business_date_end") {
            filterArr.push({
              field: "business_date",
              value: val[item],
              condition: "lteq",
            });
          } else if (item == "inventory_confirm_date_start") {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "inventory_confirm_date_end") {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getOrderAssignList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const getDropdownOptions = async () => {
      if (
        options.value.status.length !== 0 ||
        options.value.fulfillment_status.length !== 0
      )
        return;
      const { data } = await ApiBase.getTaggingData({
        short_key: ["mb_order_status", "fulfillment_order_status"],
      });
      if (data.code == 0) {
        options.value.status = data.data.mb_order_status.items;
        options.value.fulfillment_status =
          data.data.fulfillment_order_status.items;
      }
    };

    const refuseSubItem = (e) => {
      itemId.value = e.order_id;
      itemPosition.value = e.position;
      subId.value = e.fulfillment_order_id;
    };

    const cancelSubItem = (e) => {
      itemId.value = e.order_id;
      itemPosition.value = e.position;
      subId.value = e.fulfillment_order_id;
    };

    const updateSub = () => {
      const refModal =
        currentInstance.ctx._.refs[`orderAssignItem.${itemId.value}`];
      refModal.updateList();
    };

    const quickRoutingItem = (item) => {
      tableItemData.value = item;
    };

    const handleResetQuickRouting = () => {
      tableItemData.value = Object.assign({}, orderAssginTable);
    };

    const orderStatus = computed(() => {
      return (status: number) => {
        return getSalesOrderStatusMap(status, t)?.color;
      };
    });

    const goToRefundPage = (item, withReturn = false, isContinue = false) => {
      if (withReturn && !isContinue) {
        tableItemData.value = item;
        return;
      } else if (withReturn && isContinue) {
        loading.value = true;
        router.push(
          "/mb-order-routing/routing-overview/" +
            item.id +
            "/return-refund-overview"
        );
      } else {
        loading.value = true;
        router.push(
          "/mb-order-routing/routing-overview/" + item.id + "/refund-overview"
        );
      }
    };

    const isDepositOrFullPaymentOrders = (item) => {
      return item.store_id === 1 || item.store_id === 12 || item.store_id === 24;
    }

    return {
      t,
      store,
      checkPermissionFlag,
      checkUserFlag,
      formatDate,
      formatDateTime,
      getLateDays,
      SalesOrderPriorityLevel,
      getSalesOrderPriorityLevelMap,
      getSalesOrderPriorityLevelSvgStyleMap,
      isMB,
      loading,
      tableHeader,
      doubleSaleTableHeader,
      tableData,
      tableItemData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExport,
      disabledmerchantRequest,
      disabledExportUnderstockEqualization,
      disabledExportFulfillmentRejectedItems,
      quickRoutingItem,
      handleResetQuickRouting,
      getOrderAssignList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      assignableChange,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      merchantRequest,
      fileExportUnderstockEqualization,
      fileExportFulfillmentRejectedItems,
      fileExport,
      filesExport,
      orderStatus,
      options,
      itemId,
      itemPosition,
      subId,
      refuseSubItem,
      cancelSubItem,
      updateSub,
      goToRefundPage,
      isDepositOrFullPaymentOrders,
    };
  },
});


import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { OrderRequestOverviewRelationItemRelationFulfillment } from "@/core/directive/interface/orderRequest";
import { formatDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "order-request-fultillment-table",
  props: {
    items: {
      type: Array as () => OrderRequestOverviewRelationItemRelationFulfillment[],
      required: true,
      default: () => {
        return [];
      },
    },
    isShowOldFulfillmentId: {
      type: Boolean,
      default: true,
    },
    isShowSubPosition: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const options = ref({});

    return {
      t,
      formatDate,
      props,
      options,
    };
  },
});

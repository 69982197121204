import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "flex-fill" }
const _hoisted_3 = {
  key: 0,
  class: "w-75px text-end"
}
const _hoisted_4 = {
  key: 1,
  class: "w-150px text-gray-400 text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.channelOption?.label), 1),
    (_ctx.channelOption?.entity_data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.channelOption?.entity_data?.__show.commission)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass({
          'text-primary':
            Number(_ctx.channelOption.entity_data.commission.max) >= _ctx.discountValue &&
            _ctx.discountValue > 0,
        })
              }, _toDisplayString(_ctx.channelOption?.entity_data.__show.commission), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.channelOption?.channel_type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.channelOption.channel_type), 1))
      : _createCommentVNode("", true)
  ]))
}